#tour .gdp-graph {
	position: absolute;
	height: 100%; width: 100%;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	background: inherit;
	color: inherit;

	opacity: 1.0;
	transition: opacity 400ms ease-in;
	transition-delay: 200ms;	
	transform: translate3d(0,0,0);
}

#tour .gdp-graph.is-hidden {
	transition: opacity 200ms linear;
	opacity: 0;
}

/* graph */
#tour .gdp-graph .graph {
	position: absolute;
	background: #262222;
}

#tour .gdp-graph.is-desktop .graph {
	right: 0; top: 0;
	height: 100%; width: 80%;
}

#tour .gdp-graph.is-mobile .graph {
	left: 0; top: 0;
	width: 100%; height: calc(100% - var(--gdp-graph-mobile-legend-height))
}

/* -scale 100 */
#tour .gdp-graph .graph .scale-100 {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .scale-100 {
	height: 50%; width: 100%;
	border-top: 1px solid white;
}

#tour .gdp-graph.is-mobile .graph .scale-100 {
	height: 100%; width: 50%;
	border-right: 1px solid white;
}

/* -scale 195 (97.5%) */
#tour .gdp-graph .graph .scale-195 {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .scale-195 {
	border-top: 1px solid white;
	height: 97.5%; width: 100%;
}

#tour .gdp-graph.is-mobile .graph .scale-195 {
	border-right: 1px solid white;
	height: 100%; width: 97.5%;
}

/* -bar target */
#tour .gdp-graph .graph .bar-target {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .bar-target {
	width: 100%;
	background-image: linear-gradient(-180deg, var(--debt-gradient-start-color) 0%, var(--debt-gradient-start-color) 15%, var(--debt-gradient-end-color) 100%);
}

#tour .gdp-graph.is-mobile .graph .bar-target {
	height: 100%;
	text-align: right;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: flex-end;

	background-image: linear-gradient(-90deg, var(--debt-gradient-start-color) 0%, var(--debt-gradient-start-color) 30%, var(--debt-gradient-end-color) 100%);
}

#tour .gdp-graph .graph .bar-target .label {
	color: black;
}

#tour .gdp-graph.is-desktop .graph .bar-target .label {
	margin: 15px;
	font-size: 0.875em;
}

#tour .gdp-graph.is-mobile .graph .bar-target .label {
	order: 1;
	padding: 5px;
	display: block;
	transform: translateX(100%) rotate(270deg);
	transform-origin: left bottom 0;
	font-size: 0.625em;
}

/* -bar target difference*/

#tour .gdp-graph .graph .bar-target .difference {
	position: relative;
	background: transparent url("./graph-dash-tile.png");
}

#tour .gdp-graph.is-desktop .graph .bar-target .difference {
	position: relative;
	top: 0; left: 0;
	width: 100%; height: 30%;
	transition: height 400ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

#tour .gdp-graph.is-mobile .graph .bar-target .difference {
	order: 2;
	position: relative;
	top: 0; right: 0;
	width: 30%; height: 100%;
	transition: width 400ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

/* legend */

#tour .gdp-graph .legend {
	position: absolute;
	font-size: 0.75em;
	line-height: 1.2em;
}

#tour .gdp-graph.is-desktop .legend {
	top: 0; left: 0;
	width: 20%; height: 100%;
}

#tour .gdp-graph.is-mobile .legend {
	left: 0; bottom: 0;
	width: 100%; height: var(--gdp-graph-mobile-legend-height);
}

#tour .gdp-graph .legend .label {
	position: absolute;
}

#tour .gdp-graph.is-desktop .legend .label {
	width: 100%;
	text-align: right;
	padding-right: 10px;
	margin-top: -6px; /* v center */
	transition: top 430ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* we don't animate labels on mobile */
}

#tour .gdp-graph.is-desktop .legend .label.hidden {
	opacity: 0;
	transition: opacity 400ms ease;
}

#tour .gdp-graph.is-mobile .legend .label {
	position: absolute;
	height: 100%;
	vertical-align: middle;
	margin-top: 10px;
}

#tour .gdp-graph.is-desktop .legend .label.scale-100 {
	top: 50%;
}

#tour .gdp-graph.is-desktop .legend .label.scale-195 {
	top: 2.5%;
}

#tour .gdp-graph.is-mobile .legend .label.scale-100 {
	right: 50%;
}

#tour .gdp-graph.is-mobile .legend .label.scale-195 {
	right: 5%;
}

#tour .gdp-graph.is-mobile .legend .label.target {
	opacity: 0;
}
