#tour #main-side-menu {
  width: var(--main-side-menu-width);
  height: calc(100vh - var(--main-menu-height));
  position: fixed;
  top: var(--main-menu-height);
  left: 0;
  z-index: 9;
  overflow: hidden;
}

#tour.is-mobile #main-side-menu {
  display: none;
}

#tour #main-side-menu ul {
  list-style: none;
}

#tour #main-side-menu ul li {
  width: 42px;
  height: calc((100vh - var(--main-menu-height)) / 4);
}

#tour #main-side-menu ul li.raise-taxes {
  background: var(--green-color);
}

#tour #main-side-menu ul li.cut-spending {
  background: var(--blue-color);
}

#tour #main-side-menu ul li.grow-the-economy {
  background: var(--teal-color);
}

#tour #main-side-menu ul li.a-way-forward {
  background: var(--red-color);
}

#tour #main-side-menu ul li a {
  display: block;
  padding: 0;
  text-decoration: none;
}

#tour #main-side-menu ul li .label {
  transform: rotate(-90deg) translateX(calc(((100vh - var(--main-menu-height)) / 4) * -1));
  transform-origin: top left;

  width: calc((100vh - var(--main-menu-height)) / 4);
  height: 42px;

  text-align: center;
  padding: 12px 4px 0 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "basis-grotesque-medium";
  -webkit-font-smoothing: antialiased;
  font-size: 0.875em;
  color: white;
  transition: opacity 100ms ease-out;
}

#tour #main-side-menu ul li .label:hover {
  opacity: 0.6;
}

/* #tour #main-side-menu ul li:first-child .label {
  margin-top: calc((100vh - var(--main-menu-height)) / 4);
} */
