#tour .illustration-sidebar-wrapper-background {
	background-color: var(--teal-color);
	width: 100%;
}

#tour .illustration-sidebar-wrapper {
	background-color: var(--teal-color);
	max-width: 2000px;
	margin: auto;
}

#tour .illustration-sidebar-wrapper.is-desktop {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
}

#tour .illustration-sidebar-wrapper.is-desktop .illustration-sidebar-content {
	order: 1;
}

#tour .illustration-sidebar-wrapper .illustration-sidebar {
  display: flex;
  align-items: center;
	justify-content: center;
}

#tour .illustration-sidebar-wrapper .illustration-sidebar img {
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0;
	padding: 0;
}

#tour .illustration-sidebar-wrapper.is-desktop .illustration-sidebar {
	order: 2;
	position: sticky;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
	margin-right: var(--scroll-indicator-bar-width);
	overflow: hidden;
	z-index: 1;
}

/* Mobile */

#tour .illustration-sidebar-wrapper.is-mobile {
	position: relative;

	display: flex;
	flex-flow: column nowrap;
	min-height: calc(100vh);
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar {
  flex: 0 0 auto;
  position: relative;
	padding-top: var(--main-menu-mobile-height);
	height: 50vh; width: 100%;
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar img {
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar-content {
	flex: 0 0 auto;
	position: relative;
	min-height: calc(100vh - var(--main-menu-mobile-height) - 50vh);
}


/* Overrides */

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar-content .full-page-w-menu.is-first-page {
	margin-top: 0;
}