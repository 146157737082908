#tour #intro-splash-wrapper {
	background: #ffb34d;
	color: #000;
}

#tour #intro-splash-wrapper .scroll-indicator .scroll-indicator-icon svg {
	stroke: black;
}

#tour #intro-splash .tooltip {
	border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

#tour #intro-splash p {
	max-width: 500px;
}

/* split layout */

#tour #intro-splash .split-layout {
	display: flex;
}

#tour.is-desktop #intro-splash .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
}

#tour.is-mobile #intro-splash .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
}

/* Disable vcenter for mobile because square splash image 
has a lot of white space at the top */
#tour.is-mobile #intro-splash.v-center {
	align-items: flex-start;
}

#tour #intro-splash .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column:first-child {
	order: 2;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column:last-child {
	order: 1;
	flex: 0 1 auto;
	height: 50vh;
	position: relative;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 600px;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column img {
	display: block;
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}