/* Right */

#intro-page-wrapper #main-menu #main-menu-bar.is-white {
	background-image: linear-gradient(-180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

#intro-page-wrapper #main-menu #main-menu-bar.is-blue {
	background-image: linear-gradient(-180deg, var(--blue-color) 0%, var(--blue-zero-alpha-color) 100%);
}

#intro-page-wrapper #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#intro-page-wrapper #main-menu #main-menu-bar .right .build-btn {
	margin-left: 30px;
}

#intro-page-wrapper #main-menu.is-portrait.is-mobile .right {
	display: none;
}

#intro-page-wrapper #main-menu.is-landscape.is-mobile #main-menu-bar {
	padding-right: 40px;
}

@supports(padding: max(0px)) {
	#intro-page-wrapper #main-menu.is-landscape.is-mobile #main-menu-bar {
		padding-right: max(40px, env(safe-area-inset-right));
	}
}
