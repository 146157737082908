#tour #close-loopholes-page {
	background: var(--green-color);
	color: white;
}

#tour.is-mobile #close-loopholes-landing h2 {
	margin-top: var(--main-menu-mobile-height);
}

#tour #close-loopholes-landing p {
	max-width: 600px;
}
/* overrides */

#tour #close-loopholes-page .scroll-indicator {
	color: white;
	background-image: linear-gradient(-180deg, var(--green-color-zero-alpha) 0%, var(--green-color) 100%);
}


/* split layout */

#tour #close-loopholes-landing .split-layout {
	display: flex;
}

#tour.is-desktop #close-loopholes-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #close-loopholes-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
	min-height: 100vh;
}

#tour #close-loopholes-landing .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #close-loopholes-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #close-loopholes-landing .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #close-loopholes-landing .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
}

#tour.is-desktop #close-loopholes-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #close-loopholes-landing .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 500px;
}