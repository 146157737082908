:root {
	--white-color: #fff;
	--green-color: #22A750;
	--green-color-zero-alpha: rgba(34, 167, 80, 0.00);
	--dark-green-color: #0A3117;
	--dark-green-zero-alpha-color: rgba(10, 49, 23, 0.00);
	--blue-color: #2E07A9;
	--blue-zero-alpha-color: rgba(46, 7, 169, 0.00);
	--dark-blue-color: #0D0232;
	--dark-blue-zero-alpha-color: rgba(32, 4, 117, 0.00);
	--teal-color: #14AA9A;
	--teal-zero-alpha-color: rgba(20, 170, 154, 0.00);
	--dark-teal-color: #17342F;
	--dark-teal-zero-alpha-color: rgba(23, 52, 47, 0.00);
	--red-color: #EC654D;
	--red-zero-alpha-color: rgba(236, 101, 77, 0.00);
	--gray-color: #61686C;
	--gray-zero-alpha-color: rgba(97, 104, 108, 0.00);
	--dark-gray-color: #242424;
	--dark-gray-zero-alpha-color: rgba(36, 36, 36, 0.00);
	--calculator-blue-color: #36c4ee;
	--debt-gradient-start-color: #FF5A42;
	--debt-gradient-end-color: #FFCE4F;

	--main-menu-height: 60px;
	--main-side-menu-width: 40px;
	--main-menu-mobile-height: 50px;
	--sticky-sidebar-mobile-height: 30vh;
	--gdp-graph-mobile-legend-height: 52px;
	--scroll-indicator-bar-height: 80px;
	--scroll-indicator-bar-mobile-height: 48px;
	--scroll-indicator-bar-width: 8vw;
}

body {
	-webkit-text-size-adjust: none;
}

#tour {
	font-family: "DomaineSansWebRegular";
}

.tooltip {
	border-bottom: 2px solid rgba(255, 255, 255, 0.5);
	cursor: help;
}

.tooltip-bubble {
	max-width: 300px !important;
	font-family: "basis-grotesque-mono";
	font-size: 0.875em;
	line-height: 1.3em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
	padding: 25px 20px;
}

.tooltip-bubble .multi-line {
	text-align: left !important;

}

#tour .full-page {
	position: relative;
	width: 100%;
	min-height: 100vh;

	transform:translateZ(0);
}

#tour .full-page-w-menu {
	position: relative;
	width: 100%;
	overflow-x: hidden;
	transform:translateZ(0);
}

#tour.is-desktop .full-page-w-menu {
	min-height: calc(100vh - var(--main-menu-height));
}

#tour.is-mobile .full-page-w-menu {
	min-height: calc(100vh - var(--main-menu-mobile-height));
}

#tour.is-desktop .full-page-w-menu.is-first-page {
	margin-top: var(--main-menu-height);
}

#tour.is-mobile .full-page-w-menu.is-first-page {
	margin-top: var(--main-menu-mobile-height);
}

#tour.is-mobile .full-page-w-menu.is-first-[age] {
	margin-top: var(--main-menu-mobile-height);
}

#tour.is-mobile .full-page-w-menu.w-sidebar{
	width: 100%;
	min-height: calc(100vh - var(--sticky-sidebar-mobile-height));
}

#tour.is-desktop .full-page-w-menu.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-height) - var(--main-menu-height));
}

#tour.is-mobile .full-page-w-menu.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-mobile-height) - var(--main-menu-mobile-height));
}

#tour.is-desktop .full-page.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-height));
}

#tour.is-mobile .full-page.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-mobile-height));
}

/* layout */
#tour .column-70 {
	width: 67.8%;
}

#tour .column-50 {
	width: 50%;
}

#tour .column-30 {
	width: 32.2%;
}

#tour .v-center {
	display: flex;
	align-items: center;
}

/* row content */
#tour.is-desktop .row-content {
	padding: 5% var(--scroll-indicator-bar-width) 5% var(--scroll-indicator-bar-width);
	display: flex;
	align-items: center;
}

#tour.is-mobile .row-content {
	padding: 20px;
}

#tour .row-content .content h2 {
	max-width: 800px;
}

#tour .row-content .content p {
	max-width: 600px;
}

#tour .row-content .content ul.is-inset {
	margin-left: 20px;
}

#tour .row-content .content ul li {
	margin-top: 20px;
}

#tour.is-mobile .row-content .content {
	margin-top: 0px;
	max-width: 700px;
	width: 100%;
	/* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour .row-content.disabled {
	opacity: 0.6;
	transition: opacity 200ms ease;
	pointer-events: none;
}

/* Landing */

#tour .is-landing {
	padding: 10vh var(--scroll-indicator-bar-width);
}

#tour.is-mobile .is-landing .content {
	min-height: 60vh;
}
#tour .is-landing h2 {
	max-width: 700px;
}

#tour .is-landing p {
	max-width: 600px;
}

/* Scroll anchor: we use this to be able to scroll
mobile poll answers to the appropriate place
despite them scrolling behind the graph and top menu.
*/

#tour .scroll-anchor-impl {
	position: absolute;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	/* background: pink;
	opacity: 0.8; */
}

#tour.is-desktop .scroll-anchor-impl {
	top: calc(var(--main-menu-height) * -1);
	left: 0;
	height: var(--main-menu-height);
}

#tour.is-mobile .scroll-anchor-impl {
	top: calc(var(--main-menu-mobile-height) * -1);
	left: 0;
	height: var(--main-menu-mobile-height);
}


/* Text */

#tour .uppr {
	text-transform: uppercase;
}

/* Typography */

#tour a {
	color: inherit;
}

#tour h1 {
	font-size: 3.75em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour h2 {
	font-size: 2em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour p {
	font-size: 1.125em;
	line-height: 1.75rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
}

#tour h1 + p {
	margin-top: 45px;
}

#tour h2 + p {
	margin-top: 45px;
}

#tour p + p {
	margin-top: 15px;
}

#tour h1 + h2 {
	margin-top: 45px;
}

/* - mobile */
#tour.is-mobile h1 {
	font-size: 1.875em;
}

#tour.is-mobile h2 {
	font-size: 1.5em;
}

#tour.is-mobile p {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

#tour.is-mobile h1 + p {
	margin-top: 20px;
}

#tour.is-mobile h2 + p {
	margin-top: 20px;
}

#tour.is-mobile h1 + h2 {
	margin-top: 20px;
}