/* Left */
@keyframes svgFill {
	0% {
			fill: white;
	}
	100% {
			fill: black;
	}
}

@keyframes invertedSvgFill {
	0% {
			fill: black;
	}
	100% {
			fill: white;
	}
}

#main-menu #main-menu-bar .left {
	flex: 0 0 auto;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	padding-left: 20px;
	padding-right: 15px;
}

#main-menu #main-menu-bar .left .hamburger-btn {
	display: block;
	width: 22px;
	fill: black;
	text-align: center;
	margin-right: 20px;
}

#main-menu #main-menu-bar .left #main-logo svg {
	cursor: pointer;
}

#main-menu #main-menu-bar .left svg {
	fill: #000;
	animation: svgFill 100ms forwards ease-out;
}

#main-menu #main-menu-bar .left.is-inverted svg {
	fill: #fff;
  animation: invertedSvgFill 100ms forwards ease-out;
}
