#static-pages-container #main-menu #main-menu-bar {
	background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
}

/* Right */

#static-pages-container #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#static-pages-container #main-menu #main-menu-bar .right .build-btn {
	margin-left: 35px;
}

@media (max-width: 600px) {
	#static-pages-container #main-menu.is-mobile .right {
		display: none;
	}

	@supports(padding: max(0px)) {
		#static-pages-container #main-menu.is-mobile #main-menu-bar {
			padding-right: max(40px, env(safe-area-inset-right));
		}
	}
}