#tour #why-is-medicare-spending-rising {
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #why-is-medicare-spending-rising h2 {
	max-width: 700px;
}

#tour #why-is-medicare-spending-rising p {
	max-width: 600px;
}
