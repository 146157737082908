#tour #tour-economy-container {
	background: var(--teal-color);
  color: white;
}

#tour.is-desktop #tour-economy-container {
	margin-left: var(--main-side-menu-width);
}

#tour #tour-economy-container .what-else-prompt {
	margin-top: 25px;
}

/* overrides */

/* - tour poll component  */

#tour #tour-economy-container .tour-poll-container {
	background: var(--teal-color);
	color: white;
}

#tour #tour-economy-container .tour-poll-container.is-wrong {
	background: var(--gray-color);
}

/* - picker menu */

#tour #tour-economy-container .picker-menu .picker-menu-btn:hover {
	color: var(--teal-color);
}

#tour #tour-economy-container .tour-poll-container.is-wrong .picker-menu .picker-menu-btn:hover {
	color: var(--gray-color);
}

/* - gdp graph */

#tour #tour-economy-container .tour-poll-container .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--teal-color) 0%, var(--teal-zero-alpha-color) 100%);
}

#tour #tour-economy-container .tour-poll-container.is-wrong .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--gray-color) 0%, var(--gray-zero-alpha-color) 100%);
}

/* - scroll indicator */

#tour #tour-economy-container .scroll-indicator {
	background-image: linear-gradient(0deg, var(--teal-color) 0%, var(--teal-zero-alpha-color) 100%);
}

#tour #tour-economy-container .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--dark-teal-color) 0%, var(--dark-teal-zero-alpha-color) 100%);
}