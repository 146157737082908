/* Scroll Indicator */

#intro-page .scroll-indicator {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
}

#intro-page.is-desktop .scroll-indicator {
	height: var(--scroll-indicator-bar-height);
}

#intro-page.is-mobile .scroll-indicator {
	/* iPhone X */
	height: calc(var(--scroll-indicator-bar-mobile-height) + env(safe-area-inset-bottom));
}

#intro-page .scroll-indicator-bar {
	height: 100%;
	width: 100%;

	cursor: pointer;

	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
	perspective: 1000;

	width: 100%;
	color: #000;

	will-change: opacity;
	transition: opacity 150ms linear, background-color 200ms linear;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

  overflow: hidden;
  user-select: none;
}

#intro-page .scroll-indicator-bar.white {
	background: #fff;
	color: #000;
}

#intro-page .scroll-indicator-bar.red {
	background: var(--red-color);
	color: #fff;
}

#intro-page .scroll-indicator-bar.purple {
	background: transparent; /* purple shape already underneath */
	color: #fff;
}

#intro-page .scroll-indicator-bar.hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-title {
	opacity: 1;
	transition: opacity 250ms linear;
}

#intro-page .scroll-indicator-title.hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 250ms ease;
}

#intro-page.is-desktop .scroll-indicator-icon svg {
	width: 23px; height: 37px;
}

#intro-page.is-mobile .scroll-indicator-icon svg {
	width: 15.5px; height: 25px;
}

#intro-page.is-desktop .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-width);
}

#intro-page.is-mobile .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-mobile-height);
	width: var(--scroll-indicator-bar-mobile-height);
	/* iPhone X */
	margin-left: env(safe-area-inset-left);
}

#intro-page .scroll-indicator-icon.should-bounce {
	animation-name: bounce;
	animation-duration: 1.8s;
	animation-iteration-count: 3;
}

#intro-page .scroll-indicator-icon.is-hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-icon svg {
	transition: stroke 0.5s ease-in-out;
}