#tour #main-menu #main-menu-bar {
	background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
}

/* Right */

#tour #main-menu #main-menu-bar .right {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

#tour #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

#tour #main-menu.is-desktop #main-menu-bar .right .jump-to-btn {
	margin-right: 30px;
}

#tour #main-menu.is-mobile #main-menu-bar .right .jump-to-btn {
	margin-right: 20px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .progress-indicator-wrapper {
	display: block;
	width: 30px; height: 30px;
	margin-right: 12px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .progress-indicator-wrapper .CircularProgressbar-trail {
  stroke: #DEDEDE;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .jump-to-label  {
	margin-right: 10px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn svg.jump-to-icon  {
	fill: none;
	stroke: black;
	transition: transform 200ms ease-out;
	animation-direction: reverse;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn svg.flipped {
	transform: rotate(180deg); 
}

#tour #main-menu #main-menu-bar .right .divider {
	display: inline-block;
	width: 1px;
	height: 26px;
	background: rgba(0, 0, 0, 0.5);
}

#tour #main-menu #main-menu-bar .right .build-btn {
	margin-left: 30px;
}
