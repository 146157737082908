#tour #what-do-we-mean-by-cuts h2 {
	max-width: 500px;
}

#tour #what-do-we-mean-by-cuts p {
	max-width: 600px;
}


/* split layout */

#tour #what-do-we-mean-by-cuts .split-layout {
	display: flex;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
	min-height: 100vh;
}

#tour #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
	width: 100%;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #what-do-we-mean-by-cuts .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 900px;
}