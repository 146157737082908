#tour .background-graph svg {
  overflow: visible;
}

#tour .background-graph .background-graph-label-group text {
  font-family: "basis-grotesque-mono", monospace;
  -webkit-font-smoothing: antialiased;
	font-size: 0.85em;
	fill: rgba(255, 255, 255, 0.8);
}

#tour .background-graph .background-graph-label-group line {
	stroke: rgba(255, 255, 255, 0.8);
}

/* Hide peak label on short mobile devices */
@media screen and (max-height: 850px) {
	#tour.is-mobile .background-graph .background-graph-label-group.top {
		display: none;
	}
}