
#tour .sticky-sidebar-wrapper.is-desktop {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
}

#tour .sticky-sidebar-wrapper.is-desktop  .sticky-sidebar-content {
	order: 1;
}

#tour .sticky-sidebar-wrapper.is-desktop .sticky-sidebar {
	order: 2;
	position: sticky;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
	overflow: hidden;
	z-index: 1;
}

/* Mobile */

#tour .sticky-sidebar-wrapper.is-mobile {
	position: relative;

	display: flex;
	flex-flow: column nowrap;
	min-height: calc(100vh);
}

#tour .sticky-sidebar-wrapper.is-mobile .sticky-sidebar {
	flex: 0 0 auto;
	position: sticky;
	top: var(--main-menu-mobile-height);
	height: var(--sticky-sidebar-mobile-height); width: 100%;
	z-index: 1;
	overflow: hidden;
}

#tour .sticky-sidebar-wrapper.is-mobile .sticky-sidebar-content {
	flex: 0 0 auto;
	position: relative;
	min-height: calc(100vh - var(--main-menu-mobile-height) - var(--sticky-sidebar-mobile-height));
}

/*  Scroll Anchor Overrides */

#tour .sticky-sidebar-wrapper.is-desktop .scroll-anchor-impl {
	top: calc(var(--main-menu-height) * -1); 
	left: 0;
	height: var(--main-menu-height);
}

#tour .sticky-sidebar-wrapper.is-mobile .scroll-anchor-impl {
	top: calc(((var(--sticky-sidebar-mobile-height) + var(--main-menu-mobile-height)) - 20px) * -1); 
	left: 0;
	height: calc(var(--sticky-sidebar-mobile-height) + var(--main-menu-mobile-height) - 20px);
}
