#tour .poll {
	margin: 45px 0 0 0;
}

#tour .poll li {
	font-size: 2.5em;

	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;

	display: flex;
	align-items: center;
}

#tour.is-mobile .poll li {
	font-size: 1.875em;
}

#tour .poll li + li {
	margin-top: 30px;
}

#tour.is-mobile .poll li + li {
	margin-top: 30px;
}

#tour .poll input[type="radio"] {
	position: relative;
	display: inline-block;
	cursor: pointer;
	-webkit-appearance: none;

	background: transparent;
	transition: background-color 150ms ease-out;

	border: 2px solid white;
	border-radius: 20px;
	height: 20px; width: 20px;
	outline: none;
}

#tour .poll input[type="radio"]:checked {
	background: white;
}

#tour .poll label {
	cursor: pointer;
	padding-left: 20px;
	font-family: 'basis-grotesque-medium';
}
