#tour #reform-medicare-landing ol {
	list-style-type: decimal;
	padding-left: 20px;
	margin-top: 6%;
}

#tour #reform-medicare-landing li {
	margin-top: 3%;
}

#tour #reform-medicare-landing ol + p {
	margin-top: 6%;
}

