#tour .accordion {
  margin-top: 20px;
}

#tour.is-desktop .accordion {
  max-width: 750px;
}

#tour .accordion .accordion-row + .accordion-row {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

#tour .accordion .accordion-row .header {
  display: block;
  width: 100%;

  padding: 15px 0 20px 0;
  border: 0;
  cursor: pointer;
  background: transparent;
  color: inherit;

	font-size: inherit;
	line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;

  text-align: left;
  outline: 0;
}

#tour .accordion .accordion-row .header h3 {
	font-size: 1.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour.is-mobile .accordion .accordion-row .header h3 {
	font-size: 1.25em;
}

#tour .accordion .accordion-row .header .label {
  width: 100%;
  max-width: 750px;

  display: flex;
  flex-flow: row nowrap;
}

#tour .accordion .accordion-row .header .label .disclosure-indicator {
  display: block;
  width: 30px;
}

#tour .accordion .accordion-row .header .label .title {
  display: block;
}

#tour .accordion .accordion-row .body {
  opacity: 0;
  transition: opacity 450ms 100ms ease;
  overflow: hidden;
  max-width: 600px;
}

#tour .accordion .accordion-row.is-collapsed .body {
  height: 0;
}

#tour .accordion .accordion-row.is-expanded .body {
  height: auto;
  opacity: 1;
  padding: 0 0 20px 30px;
}