#tour .spending-graph {
	position: absolute;
	height: 100%; width: 100%;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	background: inherit;
	color: inherit;

	opacity: 1.0;
	transition: opacity 400ms ease-in;
	transition-delay: 200ms;	
	transform: translate3d(0,0,0);
}

#tour .spending-graph.is-hidden {
	transition: opacity 200ms linear;
	opacity: 0;
}

/* Graph */

#tour .spending-graph .graph {
	position: absolute;
}

#tour .spending-graph.is-desktop .graph {
  right: 0; top: 0;
	height: 100%; width: 80%;
}

#tour .spending-graph.is-mobile .graph {
  left: 0; top: 0;
  height: 100%; width: 100%;
  
  display: flex;
  flex-flow: row nowrap;
}

/* - Bar Segment */

#tour .spending-graph .graph .bar-segment {
  position: relative;
  overflow: hidden;
}

#tour .spending-graph .graph .bar-segment:nth-child(1) {
  background: var(--red-color);
}
#tour .spending-graph .graph .bar-segment:nth-child(2) {
  background: #A54736;
}
#tour .spending-graph .graph .bar-segment:nth-child(3) {
  background: #763327;
}
#tour .spending-graph .graph .bar-segment:nth-child(4) {
  background: #471E17;
}
#tour .spending-graph .graph .bar-segment:nth-child(5) {
  background: #180A08;
}

#tour .spending-graph.is-desktop .graph .bar-segment {
  width: 100%;
  left: 0;
  transition: height 650ms ease;
}

#tour .spending-graph.is-mobile .graph .bar-segment {
  height: 100%;
  top: 0;
  transition: width 650ms ease;
}

#tour .spending-graph .graph .bar-segment .label {
	color: white;
}

#tour .spending-graph.is-desktop .graph .bar-segment .label {
	padding: 15px;
	font-size: 0.875em;
}

#tour .spending-graph.is-mobile .graph .bar-segment .label {
  display: block;

  width: var(--sticky-sidebar-mobile-height);
  padding: 5px;

  transform: rotate(-90deg) translateX(calc(var(--sticky-sidebar-mobile-height) * -1));
  transform-origin: top left;

  font-size: 0.75em;
}

#tour .spending-graph .graph .bar-segment .percent-label {
  transition: opacity 300ms ease;
  opacity: 0;
}

#tour .spending-graph .graph .bar-segment .percent-label.is-visible {
  opacity: 1;  
}
