#intro-page #conclusion-section {
  position: relative;
  background: var(--blue-color);
  color: white;

  transform:translateZ(1px);
  z-index: 4;

  min-height: 100vh;
  width: 100%;

  transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
  transform-style: preserve-3d;
}

#intro-page #conclusion-section #conclusion-content {
  position: sticky;
  top: 0;
	width: 100%;
  height: 100vh;
}

/* Conclusion Content Fixed */

#intro-page #conclusion-section #conclusion-content-fixed {
  position: absolute;
  top: 0; left: 0;

  transform:translateZ(1px);
  z-index: 5;
}

#intro-page.is-landscape #conclusion-section #conclusion-content-fixed {
  width: 50%;
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section #conclusion-content-fixed {
  width: 100%;
  height: 50vh;
}


#intro-page #conclusion-section #conclusion-content-fixed .floating-content-copy-wrapper {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;

  /* iPhone X */
	padding-left: env(safe-area-inset-left);
}


#intro-page.is-portrait #conclusion-section #conclusion-content-fixed .floating-content-copy-wrapper {
  padding-top: var(--main-menu-mobile-height);
}



/* Conclusion Illustration Fixed */

#intro-page #conclusion-section #conclusion-illustration-fixed {
  position: absolute;
  
  transform:translateZ(1px);
  z-index: 6;

  transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	transform-style: preserve-3d;
}

#intro-page.is-landscape #conclusion-section #conclusion-illustration-fixed {
  top: 0; right: 0;
  width: 50%;
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section #conclusion-illustration-fixed {
  bottom: 0; left: 0;
  width: 100%;
  height: 50vh;
}


@media (max-width: 1200px) {
  #intro-page.is-desktop h1 {
    font-size: 2.75em;
    line-height: 1.166em;
  }
}

#intro-page.is-landscape #conclusion-section .copy-wrapper {
  width: 100%;
}

#intro-page.is-landscape #conclusion-section .copy-wrapper {
  padding-left: var(--scroll-indicator-bar-width);
}

#intro-page.is-portrait #conclusion-section .copy-wrapper {
  padding: 0 10%;
}

@media (min-width: 1200px) {
  #intro-page.is-desktop #conclusion-section .copy-wrapper p {
    max-width: 80%;
  }
}

#intro-page #conclusion-section .illustration-wrapper {
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	transform-style: preserve-3d;

  overflow: hidden;
}

#intro-page.is-mobile #conclusion-section .illustration-wrapper,
#intro-page.is-mobile #conclusion-section .copy-wrapper {
  padding: 0 20px;
}

#intro-page #conclusion-section .illustration-wrapper img {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;

  opacity: 0;
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  backface-visibility: hidden;
}

#intro-page.is-landscape #conclusion-section .illustration-wrapper img {
	transform: translate3d(10vw, 0, 0);
}

#intro-page.is-portrait #conclusion-section .illustration-wrapper img {
	transform: translate3d(0, 10vw, 0);
}

#intro-page #conclusion-section .illustration-wrapper.slide-in img {
  opacity: 1;
	transform: translate3d(0, 0, 0);
}

#intro-page.is-portrait #conclusion-section .illustration-wrapper img {
  max-height: 50vh;
}

#intro-page.is-portrait.is-desktop #conclusion-section .illustration-wrapper img {
  max-width: 400px;
  margin: auto;
}

/* Split Layout */

#intro-page.is-landscape #conclusion-section .split-layout {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

#intro-page.is-portrait #conclusion-section .split-layout {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

/* #intro-page.is-mobile #conclusion-section .split-layout {
  padding-top: var(--main-menu-mobile-height);
} */

#intro-page #conclusion-section .split-layout .split-layout-vcenter-container {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

#intro-page.is-landscape #conclusion-section .split-layout .split-layout-vcenter-container {
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section .split-layout .split-layout-vcenter-container {
  height: 50vh;
}

#intro-page.is-portrait #conclusion-section .split-layout-column {
  display: flex;
  align-items: center;
}

#intro-page.is-landscape #conclusion-section .split-layout-column {
  width: 50vw;
}

#intro-page.is-portrait #conclusion-section .split-layout-column {
  height: 50vh;
}

/* Part 16 */

#intro-page #part-16 {
	background: #fff;
  color: #000;
}

/* - Top */
#intro-page #part-16 .top {
  position: relative;
  width: 100%;
  height: 100vh;

  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#intro-page.is-desktop #part-16 .top {
	display: flex;
	flex-flow: row nowrap;
  align-items: center;
}

@media (max-height: 700px) {
  #intro-page.is-desktop #part-16 .top {
    min-height: 700px;
  }

  #intro-page.is-mobile #part-16 .top {
    min-height: 750px;
  }
}

#intro-page #part-16 .top .copy {
  /* todo */
  z-index: 10;
}

#intro-page.is-landscape #part-16 .top .copy {
  max-width: 500px;
  margin-left: 10%;
}

#intro-page.is-portrait #part-16 .top .copy {
  max-width: 100%;
}

#intro-page.is-mobile #part-16 .top .copy {
  margin: 0;
  padding: var(--main-menu-mobile-height) 20px 45px 20px;
}

@media (min-height: 600px) {
  #intro-page.is-mobile #part-16 .top .copy {
    padding: calc(var(--main-menu-mobile-height) + 45px) 20px 45px 20px;
  }
}

@media (min-height: 800px) {
  #intro-page.is-mobile #part-16 .top .copy {
    padding: calc(var(--main-menu-mobile-height) + 90px) 20px 45px 20px;
  }
}

#intro-page #part-16 .top .copy p {
  color: white;
  margin-top: 220px;
}

#intro-page.is-desktop #part-16 .top .dig-illustration-wrapper {
  position: absolute;
  bottom: 0; left: 0;
  width: 100%;
  height: 100%;
}

#intro-page.is-mobile.is-portrait #part-16 .top .dig-illustration-wrapper {
  position: absolute;
  bottom: 0; left: 0;
  width: 100%;
}

#intro-page.is-landscape #part-16 .top .dig-illustration-wrapper img {
  position: absolute;
  top: calc(50% - 97px);
  width: 100%;
  max-width: 760px;
}

#intro-page.is-desktop.is-portrait #part-16 .top .dig-illustration-wrapper img {
  position: absolute;
  top: calc(50% - 97px);
  width: 100%;
}

@media (min-width: 1600px) {
  #intro-page.is-desktop #part-16 .top .dig-illustration-wrapper img {
    right: 15%;
  }
}

@media (max-width: 1600px) {
  #intro-page.is-desktop #part-16 .top .dig-illustration-wrapper img {
    right: 0;
  }
}

#intro-page.is-mobile #part-16 .top .dig-illustration-wrapper img {
  display: block;
  width: 100%;
}

#intro-page.is-landscape #part-16 .top .dig-illustration-wrapper .filler {
  position: absolute;
  bottom: 0; left: 0;
  height: 50%; width: 100%;
  background: var(--red-color);
}

#intro-page.is-mobile.is-portrait #part-16 .top .dig-illustration-wrapper .filler {
  display: none;
}

@media (max-width: 1200px) {
  #intro-page.is-desktop #part-16 .top {
    align-items: flex-start;
    min-height: 1000px;
  }
  
  #intro-page.is-desktop #part-16 .top .copy {
    max-width: 70%;
    margin: 100px 10% 0 10%;
  }
  
  #intro-page #part-16 .top .copy p {
    margin-top: 20px;
    color: black;
    max-width: 600px;
  }
}

/* - Bottom */
#intro-page.is-landscape #part-16 .bottom-menu {
	width: 100%; height: 50vh;

	display: flex;
  flex-flow: row nowrap;
}

@media (max-height: 400px) {
  #intro-page.is-landscape #part-16 .bottom-menu {
    height: 100vh;
  }
}

#intro-page.is-landscape #part-16 .bottom-menu a {
  width: 100%;
  height: 100%;
}

#intro-page.is-portrait #part-16 .bottom-menu {
	width: 100%; height: auto;
}

#intro-page #part-16 .bottom-menu .nav-btn {
  text-decoration: none;
	color: #fff;
	display: flex;
	flex-flow: row nowrap;
  align-items: center;  
}

#intro-page.is-landscape #part-16 .bottom-menu .nav-btn {
  position: relative;
  width: 50%; height: 100%;
  justify-content: center;
}

#intro-page.is-portrait #part-16 .bottom-menu .nav-btn {
  position: relative;
  width: 100%; height: 50vh;
  max-height: 320px;
  align-items: flex-start;
  padding-top: 40px;
}

#intro-page.is-portrait.is-desktop #part-16 .bottom-menu .nav-btn {
  max-height: 300px;
}

#intro-page #part-16 .bottom-menu .nav-btn .btn-copy {
  position: relative;
}

#intro-page.is-landscape #part-16 .bottom-menu .nav-btn .btn-copy {
	width: 80%;
}

#intro-page.is-portrait #part-16 .bottom-menu .nav-btn .btn-copy {
	margin: 0 20px;
}

#intro-page.is-mobile.is-portrait #part-16 .bottom-menu .nav-btn .btn-copy {
	height: 100%;
}

#intro-page #part-16 .bottom-menu .nav-btn.tour {
  background: #ffb34d url("./illustrations/tour-thumb.png") no-repeat;
  background-size: 189px 141px;
  color: black;
}

#intro-page.is-desktop #part-16 .bottom-menu .nav-btn.tour {
  background-position: bottom 20px right 20px;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn.tour {
  background-position: bottom 1vh right 1vh;
}

#intro-page #part-16 .bottom-menu .nav-btn.calculator {
  background: var(--calculator-blue-color) url("./illustrations/calculator-thumb.png") no-repeat;
  background-size: 118px 101px;
  color: black;
}

#intro-page.is-desktop #part-16 .bottom-menu .nav-btn.calculator {
  background-position: bottom 40px right 40px;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn.calculator {
  background-position: bottom 5vh right 5vw;
}

#intro-page #part-16 .bottom-menu .nav-btn p {
	font-size: 1rem;
	max-width: 400px;
}

#intro-page #part-16 .bottom-menu .nav-btn h2 + p {
	margin-top: 20px;
}

#intro-page #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  position: absolute;
  bottom: 70px;
  left: 0;
}

@media (max-height: 600px) {
  #intro-page.is-mobile #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
    position: relative;
    bottom: auto;
    margin-top: 20px;
  }

  #intro-page #part-16 .bottom-menu .nav-btn.tour {
    background-image: none;
  }

  #intro-page #part-16 .bottom-menu .nav-btn.calculator {
    background-image: none;
  }
}


#intro-page.is-desktop #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  margin-top: 45px;
}

#intro-page #part-16 .bottom-menu .nav-btn .arrow-label-wrapper .label {
  flex: 0 0 auto;
  margin-right: 10px;
  font-family: "basis-grotesque-medium";
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
}

#intro-page #part-16 .bottom-menu .nav-btn .next-arrow-icon {
  transition: margin 150ms ease;
  margin-top: 5px;
}

#intro-page #part-16 .bottom-menu .nav-btn:hover .next-arrow-icon {
  margin-left: 15px;
}
