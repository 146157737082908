body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	-webkit-font-smoothing: subpixel-antialiased;
	color: #000;
	background: #fff;
}

@font-face {
	font-family: "DomaineSansWebRegular";
	src: local("DomaineSansWebRegular"), url(../fonts/DomaineSansTextWeb/DomaineSansTextWeb-Regular.woff) format("woff");
}

@font-face {
	font-family: "basis-grotesque-mono";
	src: local("basis-grotesque-mono"), url(../fonts/basis-grotesque-mono-web/basis-grotesque-mono.woff) format("woff");
}

@font-face {
	font-family: "basis-grotesque-medium";
	src: local("basis-grotesque-medium"), url(../fonts/basis-grotesque-medium-web/basis-grotesque-medium.woff) format("woff");
}

/* Animated route transition group */

.fade-enter {
	top: 100%;
	z-index: 1;
}

.fade-enter-active {
	top: 0;
	transition: top 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	overflow: hidden;
}

.fade-exit-active {
	top: -100%;
	transition: top 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	overflow: hidden;
}
