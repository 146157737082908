#tour #intro-landing-wrapper {
	background: var(--debt-gradient-end-color);
	color: #fff;
	position: relative;
}

#tour #intro-landing {
	background: #242424;
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #intro-landing .content {
	z-index: 1;
	min-width: 100%;
}

#tour.is-mobile.is-portrait #intro-landing .content {
	min-height: 60vh;
}

#tour #intro-landing .content p {
	max-width: 500px;
}

#tour #intro-landing-wrapper .background-graph {
	position: absolute;
	bottom: 0; left: 0;
	pointer-events: none;
}

#tour #intro-landing-wrapper .background-graph svg {
	display: block;
	fill: url(#flame-gradient);
}

/*  Overrides */

#tour #intro-landing-wrapper .scroll-indicator svg {
	stroke: var(--red-color);
}

#tour #intro-landing-wrapper .scroll-indicator {
	color: black;
	background-image: none;
	background-color: white;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group text {
	fill: #000;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.top text {
	fill: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.top line {
	stroke: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.goal text {
	fill: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.goal line {
	stroke: #fff;
}
