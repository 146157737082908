#hamburger-menu {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100vh;
	background: #000;
	color: #fff;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	z-index: 11;

	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
	transform: translateX(-100%);
}

#hamburger-menu.is-desktop {
	max-width: 300px;
}

#hamburger-menu .hamburger-content-wrapper {
	padding: 30px 20px;

	display: flex;
	flex-flow: column nowrap;

	justify-content: space-between;

	overflow-y: auto;
	overflow-x: hidden;
}

/* iPhone X */
@supports(padding: max(0px)) {
	#hamburger-menu .hamburger-content-wrapper {
		padding-left: max(20px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}

#hamburger-menu.is-desktop .hamburger-content-wrapper {
	height: calc(100vh - var(--main-menu-height)); 
}

#hamburger-menu.is-mobile .hamburger-content-wrapper {
	height: calc(100vh - var(--main-menu-mobile-height));
}

/* Hamburger Menu Bar */

#hamburger-menu .hamburger-menu-bar {
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	padding: 0 15px 0 20px;
}

/* iPhone X */
@supports(padding: max(0px)) {
	#hamburger-menu .hamburger-menu-bar {
		padding-left: max(15px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}


#hamburger-menu.is-desktop .hamburger-menu-bar {
	height: var(--main-menu-height); 
}

#hamburger-menu.is-mobile .hamburger-menu-bar {
	height: var(--main-menu-mobile-height);
}

#hamburger-menu .hamburger-menu-bar svg {
	fill: #fff;
}


#hamburger-menu .links-wrapper a {
	display: block;
	font-size: 1.25em;
	color: #fff;
	text-decoration: none;
	margin-top: 0px;
	line-height: 40px;
}

#hamburger-menu .more-info-wrapper {
	font-size: 0.9em;
	line-height: 1.45em;
}

#hamburger-menu .logo-wrapper {
	margin-top: 20px;
	width: 180px;
	padding: 12px 0 0;
}

#hamburger-menu .logo-wrapper img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

#hamburger-menu .logo-wrapper .hoover-logo {
	width: 60%;
	display: inline-block;
}

#hamburger-menu .logo-wrapper .stanford-logo {
	width: 40%;
	display: inline-block;
}

#hamburger-menu .social-wrapper {
	margin-top: 30px;
}

#hamburger-menu .social-wrapper .connect-label {
	vertical-align: top;
	color: #999999;
}

#hamburger-menu .social-wrapper .social-icon {
	width: 20px; height: 20px;
	fill: #fff;
	margin-left: 20px;
}

#hamburger-menu .copyright-wrapper {
	margin-top: 30px;
	color: #999999;
}

#hamburger-menu .additional-links-wrapper a {
	display: block;
	color: #fff;
	text-decoration: none;
}

#hamburger-menu .additional-links-wrapper {
	margin-top: 30px;
}

#hamburger-menu .additional-links-wrapper a { 
	color: #999999;
}

#hamburger-menu a:hover {
	opacity: 0.8;
}