#intro-page-wrapper {
	min-height: 100vh;
}

#intro-page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;

	z-index: 2;

	font-family: "DomaineSansWebRegular";
	color: #141E30;
}

/* Hide unsuported resolutions */
#rotate-device-overlay {
	position: fixed;
	top: 0; left: 0;
	width: 100vw;
	height: 100vh;
	background: var(--green-color);
	z-index: 100;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;


	display: none;
}

#rotate-device-overlay svg {
	width: 90px;
}

#rotate-device-overlay p {
	color: #fff;
	display: block;
	font-size: 0.875em;
}

@media screen and (max-height: 400px) and (max-width: 568px) and (orientation: landscape){
	#rotate-device-overlay {
		display: flex;
	}
}

/*  Typography  */

#intro-page h1 {
	font-size: 3.75em;
	line-height: 1.066em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#intro-page h2 {
	font-size: 2.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#intro-page p {
	font-size: 1.125em;
	line-height: 1.66rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
}

#intro-page.is-mobile h1 {
	font-size: 1.875em;
}

#intro-page.is-mobile h2 {
	font-size: 1.875em;
}

#intro-page.is-mobile p {
	font-size: 1rem;
	line-height: 1.45rem;
}

#intro-page.is-desktop h1 + p {
	margin-top: 45px;
}

#intro-page.is-mobile h1 + p {
	margin-top: 25px;
}

@media (max-height: 700px) {
  #intro-page.is-mobile h1 + p {
    margin-top: 10px;
  }
}

#intro-page.is-desktop p + h1 {
	margin-top: 45px;
}

#intro-page.is-mobile p + h1 {
	margin-top: 30px;
}

#intro-page.is-desktop p + p {
	margin-top: 15px;
}

#intro-page.is-mobile p + p {
	margin-top: 10px;
}

/* Debug Menu */

#intro-page #debug-menu {
	position: fixed;

	transform: translate3d(0, 40px, 0);
	backface-visibility: hidden;
	transform-style: preserve-3d;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	z-index: 5;
	color: yellowgreen;
}

#intro-page #debug-menu .info {
	width: 87px; height: 48px;
	background: rgba(0, 0, 0, 0.05);
	line-height: 48px;
	text-align: center;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-5px);
	}
}

/* Part */

#intro-page .part {
	min-height: 100%;
	min-height: 100vh;


	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	overflow: hidden;

	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
}

#intro-page.is-desktop .part {
	padding: 5% var(--scroll-indicator-bar-width);
}

#intro-page.is-mobile .part {
	padding: 20px;
}

#intro-page.is-portrait .part .copy {
	width: 100%;
}

#intro-page.is-desktop.is-landscape .part .copy {
	width: 60%;
	max-width: 600px;
	margin-bottom: 70px;
}

#intro-page.is-mobile.is-landscape .part .copy {
	width: 100%;
	max-width: 600px;
}

#intro-page.is-desktop.is-landscape .part .copy.final {
	margin-top: 10vh;
}

#intro-page.is-portrait .part .copy.final {
	margin-top: 20px;
}

#intro-page .part .copy .digits {
	white-space: nowrap;
	font-family: "basis-grotesque-mono", monospace;
	-webkit-font-smoothing: antialiased;
	font-size: 5.5em;
}


#intro-page.is-mobile .part .copy .digits {
	font-size: 3.5em;
}

#intro-page .part .copy .digits.green {
	color: var(--green-color);
}

#intro-page .part.fixed {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	will-change: transform;
}

#intro-page.is-landscape .part.fixed {
	height: 100%;
}

#intro-page.is-portrait .part.fixed {
	min-height: 50%;
	/* Leave room for top menu */
	padding-top: 10vh;
}

#intro-page.is-mobile.is-landscape .part.fixed {
	min-height: 470px;
	overflow: hidden;
}

/* Fill in gaps for abs positioned elements that will animate above */
#intro-page .part.fixed .content-wrapper {
	position: relative;
	padding-bottom: 320px;
	width: 100%;
}

#intro-page.is-desktop .part.fixed .content-wrapper {
	margin-top: 40px;
}

#intro-page.is-mobile .part.fixed .content-wrapper {
	margin-top: 20px;
}

#intro-page .part.fixed .content {
	position: absolute;
	width: 100%;
}

#intro-page .part .copy {
	/* iPhone X */
	padding-left: env(safe-area-inset-left);
}

#intro-page .part .copy .desc-container-top {
	position: relative;
	width: 100%;
}

#intro-page .part .copy .desc-container-top p + p {
	margin-top: 0;
}


#intro-page.is-desktop .part .copy .desc-container-top {
	min-height: 50px;
}

#intro-page.is-mobile .part .copy .desc-container-top {
	min-height: 30px;
	margin-top: 0px;
}

#intro-page .part .copy .desc-container-bottom {
	position: relative;
	height: 80px; width: 100%;
}

#intro-page .part .copy .desc-container-bottom p + p {
	margin-top: 0;
}

#intro-page.is-desktop .part .copy .desc-container-bottom {
	margin-top: 35px;
}

#intro-page.is-mobile .part .copy .desc-container-bottom {
	margin-top: 15px;
}

#intro-page .part .copy .desc {
	position: absolute;
	top: 0; left: 0;
	transition: opacity 0.5s ease-in-out;
}

#intro-page .part .copy .desc-rel {
	transition: opacity 0.5s ease-in-out;
}

#intro-page .part .copy .desc-rel.red .tooltip {
	border-bottom: 2px solid rgba(255, 90, 66, 0.4);
}

#intro-page .part .copy .desc .tooltip {
	border-bottom: 2px solid rgba(34, 167, 80, 0.4);
}

#intro-page .part .copy .desc.blue .tooltip {
	border-bottom: 2px solid rgba(46, 7, 169, 0.4);
}

#intro-page .part .copy .desc.red .tooltip {
	border-bottom: 2px solid rgba(255, 90, 66, 0.4);
}



/* Part 0 - Splash */

#intro-page #part-0 {
	color: #fff;
}

/* Fixed Splash Section */

#intro-page #fixed-splash-section {
	position: absolute;
	top: 0; left: 0;
	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

#intro-page #fixed-splash-section .splash-illustration-container {
	position: relative;

	max-width: 900px;
	max-height: 900px;

	display: flex;
	align-items: center;
	justify-content: center;
}

#intro-page.is-portrait #fixed-splash-section .splash-illustration-container {
	width: 100vw;
	height: 100vw;

	min-width: 550px;
	min-height: 550px;
}

#intro-page.is-landscape #fixed-splash-section .splash-illustration-container {
	width: 100vh;
	height: 100vh;

	min-width: 900px;
	min-height: 550px;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration {
	opacity: 0;

	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;

	perspective: 1000;
	backface-visibility: hidden;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration.is-loaded {
	animation: fadein 1000ms forwards;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
	display: block;
}

@media (min-height: 900px) {
	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
		max-height: 100%;
		width: auto;
		margin: 0 auto;
	}
}

@media (max-height: 900px) {
	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}

	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
		flex: 0 0 auto;
		display: block;
		width: 100%;
		height: auto;
	}
}

/* Splash Scroll Indicator */

#intro-page #fixed-splash-section .splash-scroll-indicator {
	position: absolute;
	left: calc(50% - var(--scroll-indicator-bar-height) / 2);
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-height);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

#intro-page.is-landscape #fixed-splash-section .splash-scroll-indicator {
	bottom: 45px;
}

#intro-page.is-portrait #fixed-splash-section .splash-scroll-indicator {
	bottom: -35vh;
}

#intro-page.is-desktop #fixed-splash-section .splash-scroll-indicator svg {
	width: 23px; height: 37px;
}

#intro-page.is-mobile #fixed-splash-section .splash-scroll-indicator svg {
	width: 15.5px; height: 25px;
}

#intro-page #fixed-splash-section .splash-scroll-indicator.should-bounce {
	animation-name: bounce;
	animation-duration: 1.8s;
	animation-iteration-count: 60;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-container {
	position: relative;
	color: white;

	display: flex;
	flex-flow: column no-wrap;
	align-items: center;
	justify-content: center;
}

#intro-page.is-landscape #fixed-splash-section .splash-illustration-container .splash-logo-container {
	width: 50vw;
	height: 50vw;
		
	max-width: 450px;
	max-height: 450px;

	padding-bottom: 120px;
}

#intro-page.is-portrait #fixed-splash-section .splash-illustration-container .splash-logo-container {
	position: relative;
	width: 70vw;
	height: 70vw;
	margin-top: -12vh;

	max-width: 270px;
	max-height: 270px;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper {
	text-align: center;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper p {
	margin-top: 2vw;
	font-size: 1.375em;
	font-family: "basis-grotesque-medium";
}

#intro-page.is-mobile #fixed-splash-section .splash-illustration-container .splash-logo-wrapper p {
	font-size: 1.125em;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper svg {
	width: 80%;
}

/* Fixed Start Section */

#intro-page #fixed-start-section {
	color: white;
	max-width: 1600px;
	margin: 0 auto;
}

#intro-page.is-mobile.is-landscape #fixed-start-section {
	min-height: 100%;
	overflow: hidden;
}

#intro-page #fixed-start-section .copy-wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

#intro-page #fixed-start-section .copy-wrapper {
	width: 100%;
}

#intro-page.is-mobile.is-landscape #fixed-start-section .copy-wrapper {
	/* Make room for the illustration on the right */
	width: calc(100% - 35vw);
}

#intro-page.is-portrait #fixed-start-section .copy-wrapper {
	height: 100vh;
	/* Make room for the illustration above */
	padding-top: 13vh;
}

#intro-page #fixed-start-section #intro-illustration {
	position: absolute;
}

#intro-page.is-landscape #fixed-start-section #intro-illustration {
	top: 0;
	left: 50%;
	height: 100vh;
	width: 50%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	padding-bottom: var(--main-menu-height);
}

#intro-page.is-portrait #fixed-start-section #intro-illustration {
	top: 0; left: 0;
	width: 100%;
	height: 45vh;

	display: flex;
	align-content: center;
}

#intro-page #fixed-start-section #intro-illustration img {
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	margin: 0 auto;
}

#intro-page.is-portrait #fixed-start-section #intro-illustration img {
	max-height: 100%;
	width: auto;
}

#intro-page.is-desktop.is-landscape #fixed-start-section #intro-illustration img {
	max-width: 453px;
	max-height: 700px;
}

#intro-page.is-mobile.is-landscape #fixed-start-section #intro-illustration img {
	max-height: 100%;
	width: auto;
}

/* Stage */

#front-stage {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

#front-stage > * {
	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	backface-visibility: hidden;	
}

#stage {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

#stage .focus-plane {
	position: relative;
	width: 100vw;
	height: 100vh;
	transform: scale(1, 1);
	transform-origin: bottom right;
	pointer-events: none;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

#stage .focus-plane svg {
	transform: translate3d(0,0,0);
	transform-style: preserve-3d;
}

#stage .focus-plane #circle {
	fill: var(--green-color);
}


#stage .focus-plane #circle-blue {
	fill: var(--blue-color);
}

#stage .focus-plane #circle-red {
	fill: var(--red-color);
}

#stage .circle-label {
	font-family: "basis-grotesque-mono", monospace;
	-webkit-font-smoothing: antialiased;
	font-size: 1.125em;
	margin-top: 2px;

	opacity: 0;
	fill: #fff;
	will-change: opacity;
	transition: opacity 0.7s;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	transform-style: preserve-3d;
}

#stage .circle-label .title {
	font-family: "basis-grotesque-medium";
	font-size: 1rem;
	opacity: 0.9;
}

#stage.is-mobile .circle-label {
	font-size: 1em;
}

#stage.is-mobile .circle-label .title {
	font-size: 0.9em;
}

#stage .focus-plane #melt-shape {
	fill: var(--red-color);
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane #slope-shape {
	fill: var(--red-color);
}

#stage .focus-plane #purple-bar-shape {
	fill: var(--blue-color);
}

#stage .focus-plane .slope-shape-label-group {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane .slope-shape-label-group text {
	font-family: "basis-grotesque-mono", monospace;
	font-size: 0.85em;
	fill: #000;
}

#stage .focus-plane .slope-shape-label-group line {
	stroke: #000;
}

#stage .focus-plane .slope-shape-label-group.inverted text {
	fill: #fff;
}

#stage .focus-plane .slope-shape-label-group.inverted line {
	stroke: #fff;
}

/* Hide peak label on short mobile devices */
@media screen and (max-height: 600px) {
	#stage.is-mobile .focus-plane .slope-shape-label-group.peak {
		display: none;
	}
}

/* Federal Debt Label */

#stage .focus-plane .federal-debt-label-group {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane .federal-debt-label-group text {
	font-family: "basis-grotesque-mono", monospace;
	font-size: 1.0625em;
	fill: #000;
}

#stage.is-mobile .focus-plane .federal-debt-label-group text {
	font-size: 1em;
}

#stage .focus-plane .federal-debt-label-group .title {
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
	font-size: 1em;
	opacity: 0.8;
}

/* Hide debt label on short mobile devices */
@media screen and (max-height: 400px) {
	#stage .focus-plane .federal-debt-label-group {
		display: none;
	}
}
