
#tour #tour-conclusion-page {
	background: var(--red-color);
	color: white;
}

#tour.is-desktop #tour-conclusion-page {
	margin-left: var(--main-side-menu-width);
}

#tour #tour-conclusion-landing p {
	max-width: 600px;
}

/* Split Layout */

#tour #tour-conclusion-landing .split-layout {
	display: flex;
}

#tour.is-desktop #tour-conclusion-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 40px 20px 20px 20px;
}

#tour #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
}

#tour.is-desktop #tour-conclusion-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #tour-conclusion-landing .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 600px;
}

/* Conclusion Content */

#tour.is-mobile #tour-conclusion-content h1 {
	margin-top: 5vh;
}

#tour #tour-conclusion-content p {
	max-width: 550px;
}


#tour #tour-conclusion-content .split-layout {
	display: flex;
}

#tour.is-desktop #tour-conclusion-content .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	max-width: 1600px;
	width: 100%;
	margin: auto;
}

#tour.is-mobile #tour-conclusion-content .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 20px;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column {
	flex: 1 1 auto;
	width: 50%;
}

#tour.is-mobile #tour-conclusion-content .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-desktop #tour-conclusion-content .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #tour-conclusion-content .split-layout .split-layout-column img  {
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0;
	padding: 0;
}

/* Animated Illustrations */

#tour #tour-conclusion-page .split-layout .split-layout-column.illustration {
	overflow: hidden;
}

#tour.is-mobile #tour-conclusion-page .split-layout .split-layout-column.illustration {
	height: 40vh;
	position: relative;
	width: 100%;
}


#tour.is-mobile.is-landscape #tour-conclusion-page .split-layout .split-layout-column.illustration {
	height: 70vh;
}

#tour.is-mobile #tour-conclusion-page .split-layout .split-layout-column.illustration img  {
	display: block;
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column:first-child {
	order: 1;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column:last-child {
	order: 2;
}


#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column:first-child {
	order: 2;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column:last-child {
	order: 1;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column.copy {
	margin-left: var(--scroll-indicator-bar-width);
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column.copy {
	margin-left: 0;
}

/* Overrides */

#tour #tour-conclusion-page .scroll-indicator {
	background-image: linear-gradient(0deg, var(--red-color) 0%, var(--red-zero-alpha-color) 100%);
}

#tour #tour-conclusion-page .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--red-color) 0%, var(--red-zero-alpha-color) 100%);
}