/* Menu */

#tour .options-menu {
	margin-top: 30px;
}

#tour .options-menu-row {
	display: flex;
	flex-flow: row wrap;
}

#tour .options-menu a {
	text-decoration: none;
}

#tour .options-menu .btn {
	height: 60px;
	color: #fff;
	font-size: 0.875em;

	margin: 20px 20px 0 0;
	padding: 0 36px 0 0;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	transition: background-color 150ms ease-out;
}

#tour .options-menu .btn .icon {
	flex: 0 0 auto;

	display: block;
	width: 64px; height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#tour .btn .icon svg {
	fill: white;
}

#tour .options-menu .btn .label {
	flex: 1 1 auto;
	white-space: nowrap;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
}

#tour .options-menu .btn:last-child {
	margin-right: 0;
}

/* - mobile */

#tour.is-mobile .options-menu .btn {
	width: 100%;
	margin-right: 0;
}

#tour.is-mobile .options-menu .btn .icon {
	width: 100px;
}

/* Taxes Btn */

#tour .options-menu .taxes.btn {
	background: var(--green-color);
	border: 2px solid var(--green-color);
}

#tour .options-menu .taxes.btn:hover {
	background: white;
}

#tour .options-menu .taxes.btn:hover .icon svg {
	fill: var(--green-color);
}

#tour .options-menu .taxes.btn:hover .label {
	color: var(--green-color);
}

/* Spending Btn */

#tour .options-menu .spending.btn {
	background: var(--blue-color);
	border: 2px solid var(--blue-color);
}

#tour .options-menu .spending.btn:hover {
	background: white;
}

#tour .options-menu .spending.btn:hover .icon svg {
	fill: var(--blue-color);
}

#tour .options-menu .spending.btn:hover .label {
	color: var(--blue-color);
}

/* Economy Btn */

#tour .options-menu .economy.btn {
	background: var(--teal-color);
	border: 2px solid var(--teal-color);
}

#tour .options-menu .economy.btn:hover {
	background: white;
}

#tour .options-menu .economy.btn:hover .icon svg {
	fill: var(--teal-color);
}

#tour .options-menu .economy.btn:hover .label {
	color: var(--teal-color);
}

/* Conclusion Btn */

#tour .options-menu .conclusion.btn {
	background: var(--red-color);
	border: 2px solid var(--red-color);
}

#tour .options-menu .conclusion.btn:hover {
	background: white;
}

#tour .options-menu .conclusion.btn:hover .icon svg {
	fill: var(--red-color);
}

#tour .options-menu .conclusion.btn:hover .label {
	color: var(--red-color);
}