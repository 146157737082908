#hamburger-menu {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100vh;
	background: #000;
	color: #fff;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	z-index: 11;

	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
	transform: translateX(-100%);
}

#hamburger-menu.is-desktop {
	max-width: 300px;
}

#hamburger-menu .hamburger-content-wrapper {
	padding: 30px 20px;

	display: flex;
	flex-flow: column nowrap;

	justify-content: space-between;

	overflow-y: auto;
	overflow-x: hidden;
}

/* iPhone X */
@supports(padding: max(0px)) {
	#hamburger-menu .hamburger-content-wrapper {
		padding-left: max(20px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}

#hamburger-menu.is-desktop .hamburger-content-wrapper {
	height: calc(100vh - var(--main-menu-height)); 
}

#hamburger-menu.is-mobile .hamburger-content-wrapper {
	height: calc(100vh - var(--main-menu-mobile-height));
}

/* Hamburger Menu Bar */

#hamburger-menu .hamburger-menu-bar {
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	padding: 0 15px 0 20px;
}

/* iPhone X */
@supports(padding: max(0px)) {
	#hamburger-menu .hamburger-menu-bar {
		padding-left: max(15px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}


#hamburger-menu.is-desktop .hamburger-menu-bar {
	height: var(--main-menu-height); 
}

#hamburger-menu.is-mobile .hamburger-menu-bar {
	height: var(--main-menu-mobile-height);
}

#hamburger-menu .hamburger-menu-bar svg {
	fill: #fff;
}


#hamburger-menu .links-wrapper a {
	display: block;
	font-size: 1.25em;
	color: #fff;
	text-decoration: none;
	margin-top: 0px;
	line-height: 40px;
}

#hamburger-menu .more-info-wrapper {
	font-size: 0.9em;
	line-height: 1.45em;
}

#hamburger-menu .logo-wrapper {
	margin-top: 20px;
	width: 180px;
	padding: 12px 0 0;
}

#hamburger-menu .logo-wrapper img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

#hamburger-menu .logo-wrapper .hoover-logo {
	width: 60%;
	display: inline-block;
}

#hamburger-menu .logo-wrapper .stanford-logo {
	width: 40%;
	display: inline-block;
}

#hamburger-menu .social-wrapper {
	margin-top: 30px;
}

#hamburger-menu .social-wrapper .connect-label {
	vertical-align: top;
	color: #999999;
}

#hamburger-menu .social-wrapper .social-icon {
	width: 20px; height: 20px;
	fill: #fff;
	margin-left: 20px;
}

#hamburger-menu .copyright-wrapper {
	margin-top: 30px;
	color: #999999;
}

#hamburger-menu .additional-links-wrapper a {
	display: block;
	color: #fff;
	text-decoration: none;
}

#hamburger-menu .additional-links-wrapper {
	margin-top: 30px;
}

#hamburger-menu .additional-links-wrapper a { 
	color: #999999;
}

#hamburger-menu a:hover {
	opacity: 0.8;
}
/* Left */
@keyframes svgFill {
	0% {
			fill: white;
	}
	100% {
			fill: black;
	}
}

@keyframes invertedSvgFill {
	0% {
			fill: black;
	}
	100% {
			fill: white;
	}
}

#main-menu #main-menu-bar .left {
	flex: 0 0 auto;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	padding-left: 20px;
	padding-right: 15px;
}

#main-menu #main-menu-bar .left .hamburger-btn {
	display: block;
	width: 22px;
	fill: black;
	text-align: center;
	margin-right: 20px;
}

#main-menu #main-menu-bar .left #main-logo svg {
	cursor: pointer;
}

#main-menu #main-menu-bar .left svg {
	fill: #000;
	animation: svgFill 100ms forwards ease-out;
}

#main-menu #main-menu-bar .left.is-inverted svg {
	fill: #fff;
  animation: invertedSvgFill 100ms forwards ease-out;
}

#main-menu #main-menu-bar .right {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}
#main-menu {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
  z-index: 10;
  
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

#main-menu #main-menu-bar {
	position: absolute;
	top: 0; left: 0;
  width: 100%;
  z-index: 11;

	color: #000;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
  font-size: 0.875em;
  
  transition: opacity 200ms ease-in-out;
	opacity: 1;
}

#main-menu #main-menu-bar a {
	text-decoration: none;
	color: inherit;
	transition: opacity 100ms ease-out, color 100ms ease-out;
}

#main-menu #main-menu-bar a:hover {
	opacity: 0.6;
}

#main-menu.is-desktop #main-menu-bar {
	padding: 0 40px 0 0;
	height: var(--main-menu-height); 
}

#main-menu.is-mobile #main-menu-bar {
	position: relative;
	height: var(--main-menu-mobile-height);
	/* iPhone X */
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

#main-menu.is-inverted #main-menu-bar {
  color: white;
}

#main-menu.is-hidden #main-menu-bar {
	opacity: 0;
}


/* Right */

#intro-page-wrapper #main-menu #main-menu-bar.is-white {
	background-image: linear-gradient(-180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

#intro-page-wrapper #main-menu #main-menu-bar.is-blue {
	background-image: linear-gradient(-180deg, var(--blue-color) 0%, var(--blue-zero-alpha-color) 100%);
}

#intro-page-wrapper #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#intro-page-wrapper #main-menu #main-menu-bar .right .build-btn {
	margin-left: 30px;
}

#intro-page-wrapper #main-menu.is-portrait.is-mobile .right {
	display: none;
}

#intro-page-wrapper #main-menu.is-landscape.is-mobile #main-menu-bar {
	padding-right: 40px;
}

@supports(padding: max(0px)) {
	#intro-page-wrapper #main-menu.is-landscape.is-mobile #main-menu-bar {
		padding-right: max(40px, env(safe-area-inset-right));
	}
}

#intro-page #conclusion-section {
  position: relative;
  background: var(--blue-color);
  color: white;

  transform:translateZ(1px);
  z-index: 4;

  min-height: 100vh;
  width: 100%;

  transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
  transform-style: preserve-3d;
}

#intro-page #conclusion-section #conclusion-content {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
	width: 100%;
  height: 100vh;
}

/* Conclusion Content Fixed */

#intro-page #conclusion-section #conclusion-content-fixed {
  position: absolute;
  top: 0; left: 0;

  transform:translateZ(1px);
  z-index: 5;
}

#intro-page.is-landscape #conclusion-section #conclusion-content-fixed {
  width: 50%;
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section #conclusion-content-fixed {
  width: 100%;
  height: 50vh;
}


#intro-page #conclusion-section #conclusion-content-fixed .floating-content-copy-wrapper {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;

  /* iPhone X */
	padding-left: env(safe-area-inset-left);
}


#intro-page.is-portrait #conclusion-section #conclusion-content-fixed .floating-content-copy-wrapper {
  padding-top: var(--main-menu-mobile-height);
}



/* Conclusion Illustration Fixed */

#intro-page #conclusion-section #conclusion-illustration-fixed {
  position: absolute;
  
  transform:translateZ(1px);
  z-index: 6;

  transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-style: preserve-3d;
}

#intro-page.is-landscape #conclusion-section #conclusion-illustration-fixed {
  top: 0; right: 0;
  width: 50%;
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section #conclusion-illustration-fixed {
  bottom: 0; left: 0;
  width: 100%;
  height: 50vh;
}


@media (max-width: 1200px) {
  #intro-page.is-desktop h1 {
    font-size: 2.75em;
    line-height: 1.166em;
  }
}

#intro-page.is-landscape #conclusion-section .copy-wrapper {
  width: 100%;
}

#intro-page.is-landscape #conclusion-section .copy-wrapper {
  padding-left: var(--scroll-indicator-bar-width);
}

#intro-page.is-portrait #conclusion-section .copy-wrapper {
  padding: 0 10%;
}

@media (min-width: 1200px) {
  #intro-page.is-desktop #conclusion-section .copy-wrapper p {
    max-width: 80%;
  }
}

#intro-page #conclusion-section .illustration-wrapper {
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-style: preserve-3d;

  overflow: hidden;
}

#intro-page.is-mobile #conclusion-section .illustration-wrapper,
#intro-page.is-mobile #conclusion-section .copy-wrapper {
  padding: 0 20px;
}

#intro-page #conclusion-section .illustration-wrapper img {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;

  opacity: 0;
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

#intro-page.is-landscape #conclusion-section .illustration-wrapper img {
	transform: translate3d(10vw, 0, 0);
}

#intro-page.is-portrait #conclusion-section .illustration-wrapper img {
	transform: translate3d(0, 10vw, 0);
}

#intro-page #conclusion-section .illustration-wrapper.slide-in img {
  opacity: 1;
	transform: translate3d(0, 0, 0);
}

#intro-page.is-portrait #conclusion-section .illustration-wrapper img {
  max-height: 50vh;
}

#intro-page.is-portrait.is-desktop #conclusion-section .illustration-wrapper img {
  max-width: 400px;
  margin: auto;
}

/* Split Layout */

#intro-page.is-landscape #conclusion-section .split-layout {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

#intro-page.is-portrait #conclusion-section .split-layout {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

/* #intro-page.is-mobile #conclusion-section .split-layout {
  padding-top: var(--main-menu-mobile-height);
} */

#intro-page #conclusion-section .split-layout .split-layout-vcenter-container {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

#intro-page.is-landscape #conclusion-section .split-layout .split-layout-vcenter-container {
  height: 100vh;
}

#intro-page.is-portrait #conclusion-section .split-layout .split-layout-vcenter-container {
  height: 50vh;
}

#intro-page.is-portrait #conclusion-section .split-layout-column {
  display: flex;
  align-items: center;
}

#intro-page.is-landscape #conclusion-section .split-layout-column {
  width: 50vw;
}

#intro-page.is-portrait #conclusion-section .split-layout-column {
  height: 50vh;
}

/* Part 16 */

#intro-page #part-16 {
	background: #fff;
  color: #000;
}

/* - Top */
#intro-page #part-16 .top {
  position: relative;
  width: 100%;
  height: 100vh;

  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#intro-page.is-desktop #part-16 .top {
	display: flex;
	flex-flow: row nowrap;
  align-items: center;
}

@media (max-height: 700px) {
  #intro-page.is-desktop #part-16 .top {
    min-height: 700px;
  }

  #intro-page.is-mobile #part-16 .top {
    min-height: 750px;
  }
}

#intro-page #part-16 .top .copy {
  /* todo */
  z-index: 10;
}

#intro-page.is-landscape #part-16 .top .copy {
  max-width: 500px;
  margin-left: 10%;
}

#intro-page.is-portrait #part-16 .top .copy {
  max-width: 100%;
}

#intro-page.is-mobile #part-16 .top .copy {
  margin: 0;
  padding: var(--main-menu-mobile-height) 20px 45px 20px;
}

@media (min-height: 600px) {
  #intro-page.is-mobile #part-16 .top .copy {
    padding: calc(var(--main-menu-mobile-height) + 45px) 20px 45px 20px;
  }
}

@media (min-height: 800px) {
  #intro-page.is-mobile #part-16 .top .copy {
    padding: calc(var(--main-menu-mobile-height) + 90px) 20px 45px 20px;
  }
}

#intro-page #part-16 .top .copy p {
  color: white;
  margin-top: 220px;
}

#intro-page.is-desktop #part-16 .top .dig-illustration-wrapper {
  position: absolute;
  bottom: 0; left: 0;
  width: 100%;
  height: 100%;
}

#intro-page.is-mobile.is-portrait #part-16 .top .dig-illustration-wrapper {
  position: absolute;
  bottom: 0; left: 0;
  width: 100%;
}

#intro-page.is-landscape #part-16 .top .dig-illustration-wrapper img {
  position: absolute;
  top: calc(50% - 97px);
  width: 100%;
  max-width: 760px;
}

#intro-page.is-desktop.is-portrait #part-16 .top .dig-illustration-wrapper img {
  position: absolute;
  top: calc(50% - 97px);
  width: 100%;
}

@media (min-width: 1600px) {
  #intro-page.is-desktop #part-16 .top .dig-illustration-wrapper img {
    right: 15%;
  }
}

@media (max-width: 1600px) {
  #intro-page.is-desktop #part-16 .top .dig-illustration-wrapper img {
    right: 0;
  }
}

#intro-page.is-mobile #part-16 .top .dig-illustration-wrapper img {
  display: block;
  width: 100%;
}

#intro-page.is-landscape #part-16 .top .dig-illustration-wrapper .filler {
  position: absolute;
  bottom: 0; left: 0;
  height: 50%; width: 100%;
  background: var(--red-color);
}

#intro-page.is-mobile.is-portrait #part-16 .top .dig-illustration-wrapper .filler {
  display: none;
}

@media (max-width: 1200px) {
  #intro-page.is-desktop #part-16 .top {
    align-items: flex-start;
    min-height: 1000px;
  }
  
  #intro-page.is-desktop #part-16 .top .copy {
    max-width: 70%;
    margin: 100px 10% 0 10%;
  }
  
  #intro-page #part-16 .top .copy p {
    margin-top: 20px;
    color: black;
    max-width: 600px;
  }
}

/* - Bottom */
#intro-page.is-landscape #part-16 .bottom-menu {
	width: 100%; height: 50vh;

	display: flex;
  flex-flow: row nowrap;
}

@media (max-height: 400px) {
  #intro-page.is-landscape #part-16 .bottom-menu {
    height: 100vh;
  }
}

#intro-page.is-landscape #part-16 .bottom-menu a {
  width: 100%;
  height: 100%;
}

#intro-page.is-portrait #part-16 .bottom-menu {
	width: 100%; height: auto;
}

#intro-page #part-16 .bottom-menu .nav-btn {
  text-decoration: none;
	color: #fff;
	display: flex;
	flex-flow: row nowrap;
  align-items: center;  
}

#intro-page.is-landscape #part-16 .bottom-menu .nav-btn {
  position: relative;
  width: 50%; height: 100%;
  justify-content: center;
}

#intro-page.is-portrait #part-16 .bottom-menu .nav-btn {
  position: relative;
  width: 100%; height: 50vh;
  max-height: 320px;
  align-items: flex-start;
  padding-top: 40px;
}

#intro-page.is-portrait.is-desktop #part-16 .bottom-menu .nav-btn {
  max-height: 300px;
}

#intro-page #part-16 .bottom-menu .nav-btn .btn-copy {
  position: relative;
}

#intro-page.is-landscape #part-16 .bottom-menu .nav-btn .btn-copy {
	width: 80%;
}

#intro-page.is-portrait #part-16 .bottom-menu .nav-btn .btn-copy {
	margin: 0 20px;
}

#intro-page.is-mobile.is-portrait #part-16 .bottom-menu .nav-btn .btn-copy {
	height: 100%;
}

#intro-page #part-16 .bottom-menu .nav-btn.tour {
  background: #ffb34d url(/static/media/tour-thumb.888047fe.png) no-repeat;
  background-size: 189px 141px;
  color: black;
}

#intro-page.is-desktop #part-16 .bottom-menu .nav-btn.tour {
  background-position: bottom 20px right 20px;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn.tour {
  background-position: bottom 1vh right 1vh;
}

#intro-page #part-16 .bottom-menu .nav-btn.calculator {
  background: var(--calculator-blue-color) url(/static/media/calculator-thumb.76e7fdfe.png) no-repeat;
  background-size: 118px 101px;
  color: black;
}

#intro-page.is-desktop #part-16 .bottom-menu .nav-btn.calculator {
  background-position: bottom 40px right 40px;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn.calculator {
  background-position: bottom 5vh right 5vw;
}

#intro-page #part-16 .bottom-menu .nav-btn p {
	font-size: 1rem;
	max-width: 400px;
}

#intro-page #part-16 .bottom-menu .nav-btn h2 + p {
	margin-top: 20px;
}

#intro-page #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

#intro-page.is-mobile #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  position: absolute;
  bottom: 70px;
  left: 0;
}

@media (max-height: 600px) {
  #intro-page.is-mobile #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
    position: relative;
    bottom: auto;
    margin-top: 20px;
  }

  #intro-page #part-16 .bottom-menu .nav-btn.tour {
    background-image: none;
  }

  #intro-page #part-16 .bottom-menu .nav-btn.calculator {
    background-image: none;
  }
}


#intro-page.is-desktop #part-16 .bottom-menu .nav-btn .arrow-label-wrapper {
  margin-top: 45px;
}

#intro-page #part-16 .bottom-menu .nav-btn .arrow-label-wrapper .label {
  flex: 0 0 auto;
  margin-right: 10px;
  font-family: "basis-grotesque-medium";
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
}

#intro-page #part-16 .bottom-menu .nav-btn .next-arrow-icon {
  transition: margin 150ms ease;
  margin-top: 5px;
}

#intro-page #part-16 .bottom-menu .nav-btn:hover .next-arrow-icon {
  margin-left: 15px;
}

/* Scroll Indicator */

#intro-page .scroll-indicator {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
}

#intro-page.is-desktop .scroll-indicator {
	height: var(--scroll-indicator-bar-height);
}

#intro-page.is-mobile .scroll-indicator {
	/* iPhone X */
	height: calc(var(--scroll-indicator-bar-mobile-height) + env(safe-area-inset-bottom));
}

#intro-page .scroll-indicator-bar {
	height: 100%;
	width: 100%;

	cursor: pointer;

	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	perspective: 1000;

	width: 100%;
	color: #000;

	will-change: opacity;
	transition: opacity 150ms linear, background-color 200ms linear;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}

#intro-page .scroll-indicator-bar.white {
	background: #fff;
	color: #000;
}

#intro-page .scroll-indicator-bar.red {
	background: var(--red-color);
	color: #fff;
}

#intro-page .scroll-indicator-bar.purple {
	background: transparent; /* purple shape already underneath */
	color: #fff;
}

#intro-page .scroll-indicator-bar.hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-title {
	opacity: 1;
	transition: opacity 250ms linear;
}

#intro-page .scroll-indicator-title.hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 250ms ease;
}

#intro-page.is-desktop .scroll-indicator-icon svg {
	width: 23px; height: 37px;
}

#intro-page.is-mobile .scroll-indicator-icon svg {
	width: 15.5px; height: 25px;
}

#intro-page.is-desktop .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-width);
}

#intro-page.is-mobile .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-mobile-height);
	width: var(--scroll-indicator-bar-mobile-height);
	/* iPhone X */
	margin-left: env(safe-area-inset-left);
}

#intro-page .scroll-indicator-icon.should-bounce {
	animation-name: bounce;
	animation-duration: 1.8s;
	animation-iteration-count: 3;
}

#intro-page .scroll-indicator-icon.is-hidden {
	opacity: 0;
}

#intro-page .scroll-indicator-icon svg {
	transition: stroke 0.5s ease-in-out;
}
#intro-page-wrapper {
	min-height: 100vh;
}

#intro-page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;

	z-index: 2;

	font-family: "DomaineSansWebRegular";
	color: #141E30;
}

/* Hide unsuported resolutions */
#rotate-device-overlay {
	position: fixed;
	top: 0; left: 0;
	width: 100vw;
	height: 100vh;
	background: var(--green-color);
	z-index: 100;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;


	display: none;
}

#rotate-device-overlay svg {
	width: 90px;
}

#rotate-device-overlay p {
	color: #fff;
	display: block;
	font-size: 0.875em;
}

@media screen and (max-height: 400px) and (max-width: 568px) and (orientation: landscape){
	#rotate-device-overlay {
		display: flex;
	}
}

/*  Typography  */

#intro-page h1 {
	font-size: 3.75em;
	line-height: 1.066em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#intro-page h2 {
	font-size: 2.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#intro-page p {
	font-size: 1.125em;
	line-height: 1.66rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
}

#intro-page.is-mobile h1 {
	font-size: 1.875em;
}

#intro-page.is-mobile h2 {
	font-size: 1.875em;
}

#intro-page.is-mobile p {
	font-size: 1rem;
	line-height: 1.45rem;
}

#intro-page.is-desktop h1 + p {
	margin-top: 45px;
}

#intro-page.is-mobile h1 + p {
	margin-top: 25px;
}

@media (max-height: 700px) {
  #intro-page.is-mobile h1 + p {
    margin-top: 10px;
  }
}

#intro-page.is-desktop p + h1 {
	margin-top: 45px;
}

#intro-page.is-mobile p + h1 {
	margin-top: 30px;
}

#intro-page.is-desktop p + p {
	margin-top: 15px;
}

#intro-page.is-mobile p + p {
	margin-top: 10px;
}

/* Debug Menu */

#intro-page #debug-menu {
	position: fixed;

	transform: translate3d(0, 40px, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-style: preserve-3d;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	z-index: 5;
	color: yellowgreen;
}

#intro-page #debug-menu .info {
	width: 87px; height: 48px;
	background: rgba(0, 0, 0, 0.05);
	line-height: 48px;
	text-align: center;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-5px);
	}
}

/* Part */

#intro-page .part {
	min-height: 100%;
	min-height: 100vh;


	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	overflow: hidden;

	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
}

#intro-page.is-desktop .part {
	padding: 5% var(--scroll-indicator-bar-width);
}

#intro-page.is-mobile .part {
	padding: 20px;
}

#intro-page.is-portrait .part .copy {
	width: 100%;
}

#intro-page.is-desktop.is-landscape .part .copy {
	width: 60%;
	max-width: 600px;
	margin-bottom: 70px;
}

#intro-page.is-mobile.is-landscape .part .copy {
	width: 100%;
	max-width: 600px;
}

#intro-page.is-desktop.is-landscape .part .copy.final {
	margin-top: 10vh;
}

#intro-page.is-portrait .part .copy.final {
	margin-top: 20px;
}

#intro-page .part .copy .digits {
	white-space: nowrap;
	font-family: "basis-grotesque-mono", monospace;
	-webkit-font-smoothing: antialiased;
	font-size: 5.5em;
}


#intro-page.is-mobile .part .copy .digits {
	font-size: 3.5em;
}

#intro-page .part .copy .digits.green {
	color: var(--green-color);
}

#intro-page .part.fixed {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	will-change: transform;
}

#intro-page.is-landscape .part.fixed {
	height: 100%;
}

#intro-page.is-portrait .part.fixed {
	min-height: 50%;
	/* Leave room for top menu */
	padding-top: 10vh;
}

#intro-page.is-mobile.is-landscape .part.fixed {
	min-height: 470px;
	overflow: hidden;
}

/* Fill in gaps for abs positioned elements that will animate above */
#intro-page .part.fixed .content-wrapper {
	position: relative;
	padding-bottom: 320px;
	width: 100%;
}

#intro-page.is-desktop .part.fixed .content-wrapper {
	margin-top: 40px;
}

#intro-page.is-mobile .part.fixed .content-wrapper {
	margin-top: 20px;
}

#intro-page .part.fixed .content {
	position: absolute;
	width: 100%;
}

#intro-page .part .copy {
	/* iPhone X */
	padding-left: env(safe-area-inset-left);
}

#intro-page .part .copy .desc-container-top {
	position: relative;
	width: 100%;
}

#intro-page .part .copy .desc-container-top p + p {
	margin-top: 0;
}


#intro-page.is-desktop .part .copy .desc-container-top {
	min-height: 50px;
}

#intro-page.is-mobile .part .copy .desc-container-top {
	min-height: 30px;
	margin-top: 0px;
}

#intro-page .part .copy .desc-container-bottom {
	position: relative;
	height: 80px; width: 100%;
}

#intro-page .part .copy .desc-container-bottom p + p {
	margin-top: 0;
}

#intro-page.is-desktop .part .copy .desc-container-bottom {
	margin-top: 35px;
}

#intro-page.is-mobile .part .copy .desc-container-bottom {
	margin-top: 15px;
}

#intro-page .part .copy .desc {
	position: absolute;
	top: 0; left: 0;
	transition: opacity 0.5s ease-in-out;
}

#intro-page .part .copy .desc-rel {
	transition: opacity 0.5s ease-in-out;
}

#intro-page .part .copy .desc-rel.red .tooltip {
	border-bottom: 2px solid rgba(255, 90, 66, 0.4);
}

#intro-page .part .copy .desc .tooltip {
	border-bottom: 2px solid rgba(34, 167, 80, 0.4);
}

#intro-page .part .copy .desc.blue .tooltip {
	border-bottom: 2px solid rgba(46, 7, 169, 0.4);
}

#intro-page .part .copy .desc.red .tooltip {
	border-bottom: 2px solid rgba(255, 90, 66, 0.4);
}



/* Part 0 - Splash */

#intro-page #part-0 {
	color: #fff;
}

/* Fixed Splash Section */

#intro-page #fixed-splash-section {
	position: absolute;
	top: 0; left: 0;
	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

#intro-page #fixed-splash-section .splash-illustration-container {
	position: relative;

	max-width: 900px;
	max-height: 900px;

	display: flex;
	align-items: center;
	justify-content: center;
}

#intro-page.is-portrait #fixed-splash-section .splash-illustration-container {
	width: 100vw;
	height: 100vw;

	min-width: 550px;
	min-height: 550px;
}

#intro-page.is-landscape #fixed-splash-section .splash-illustration-container {
	width: 100vh;
	height: 100vh;

	min-width: 900px;
	min-height: 550px;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration {
	opacity: 0;

	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;

	perspective: 1000;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration.is-loaded {
	animation: fadein 1000ms forwards;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
	display: block;
}

@media (min-height: 900px) {
	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
		max-height: 100%;
		width: auto;
		margin: 0 auto;
	}
}

@media (max-height: 900px) {
	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}

	#intro-page #fixed-splash-section .splash-illustration-container .splash-illustration img {
		flex: 0 0 auto;
		display: block;
		width: 100%;
		height: auto;
	}
}

/* Splash Scroll Indicator */

#intro-page #fixed-splash-section .splash-scroll-indicator {
	position: absolute;
	left: calc(50% - var(--scroll-indicator-bar-height) / 2);
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-height);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

#intro-page.is-landscape #fixed-splash-section .splash-scroll-indicator {
	bottom: 45px;
}

#intro-page.is-portrait #fixed-splash-section .splash-scroll-indicator {
	bottom: -35vh;
}

#intro-page.is-desktop #fixed-splash-section .splash-scroll-indicator svg {
	width: 23px; height: 37px;
}

#intro-page.is-mobile #fixed-splash-section .splash-scroll-indicator svg {
	width: 15.5px; height: 25px;
}

#intro-page #fixed-splash-section .splash-scroll-indicator.should-bounce {
	animation-name: bounce;
	animation-duration: 1.8s;
	animation-iteration-count: 60;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-container {
	position: relative;
	color: white;

	display: flex;
	flex-flow: column no-wrap;
	align-items: center;
	justify-content: center;
}

#intro-page.is-landscape #fixed-splash-section .splash-illustration-container .splash-logo-container {
	width: 50vw;
	height: 50vw;
		
	max-width: 450px;
	max-height: 450px;

	padding-bottom: 120px;
}

#intro-page.is-portrait #fixed-splash-section .splash-illustration-container .splash-logo-container {
	position: relative;
	width: 70vw;
	height: 70vw;
	margin-top: -12vh;

	max-width: 270px;
	max-height: 270px;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper {
	text-align: center;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper p {
	margin-top: 2vw;
	font-size: 1.375em;
	font-family: "basis-grotesque-medium";
}

#intro-page.is-mobile #fixed-splash-section .splash-illustration-container .splash-logo-wrapper p {
	font-size: 1.125em;
}

#intro-page #fixed-splash-section .splash-illustration-container .splash-logo-wrapper svg {
	width: 80%;
}

/* Fixed Start Section */

#intro-page #fixed-start-section {
	color: white;
	max-width: 1600px;
	margin: 0 auto;
}

#intro-page.is-mobile.is-landscape #fixed-start-section {
	min-height: 100%;
	overflow: hidden;
}

#intro-page #fixed-start-section .copy-wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

#intro-page #fixed-start-section .copy-wrapper {
	width: 100%;
}

#intro-page.is-mobile.is-landscape #fixed-start-section .copy-wrapper {
	/* Make room for the illustration on the right */
	width: calc(100% - 35vw);
}

#intro-page.is-portrait #fixed-start-section .copy-wrapper {
	height: 100vh;
	/* Make room for the illustration above */
	padding-top: 13vh;
}

#intro-page #fixed-start-section #intro-illustration {
	position: absolute;
}

#intro-page.is-landscape #fixed-start-section #intro-illustration {
	top: 0;
	left: 50%;
	height: 100vh;
	width: 50%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	padding-bottom: var(--main-menu-height);
}

#intro-page.is-portrait #fixed-start-section #intro-illustration {
	top: 0; left: 0;
	width: 100%;
	height: 45vh;

	display: flex;
	align-content: center;
}

#intro-page #fixed-start-section #intro-illustration img {
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	margin: 0 auto;
}

#intro-page.is-portrait #fixed-start-section #intro-illustration img {
	max-height: 100%;
	width: auto;
}

#intro-page.is-desktop.is-landscape #fixed-start-section #intro-illustration img {
	max-width: 453px;
	max-height: 700px;
}

#intro-page.is-mobile.is-landscape #fixed-start-section #intro-illustration img {
	max-height: 100%;
	width: auto;
}

/* Stage */

#front-stage {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

#front-stage > * {
	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;	
}

#stage {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

#stage .focus-plane {
	position: relative;
	width: 100vw;
	height: 100vh;
	transform: scale(1, 1);
	transform-origin: bottom right;
	pointer-events: none;

	transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

#stage .focus-plane svg {
	transform: translate3d(0,0,0);
	transform-style: preserve-3d;
}

#stage .focus-plane #circle {
	fill: var(--green-color);
}


#stage .focus-plane #circle-blue {
	fill: var(--blue-color);
}

#stage .focus-plane #circle-red {
	fill: var(--red-color);
}

#stage .circle-label {
	font-family: "basis-grotesque-mono", monospace;
	-webkit-font-smoothing: antialiased;
	font-size: 1.125em;
	margin-top: 2px;

	opacity: 0;
	fill: #fff;
	will-change: opacity;
	transition: opacity 0.7s;
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-style: preserve-3d;
}

#stage .circle-label .title {
	font-family: "basis-grotesque-medium";
	font-size: 1rem;
	opacity: 0.9;
}

#stage.is-mobile .circle-label {
	font-size: 1em;
}

#stage.is-mobile .circle-label .title {
	font-size: 0.9em;
}

#stage .focus-plane #melt-shape {
	fill: var(--red-color);
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane #slope-shape {
	fill: var(--red-color);
}

#stage .focus-plane #purple-bar-shape {
	fill: var(--blue-color);
}

#stage .focus-plane .slope-shape-label-group {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane .slope-shape-label-group text {
	font-family: "basis-grotesque-mono", monospace;
	font-size: 0.85em;
	fill: #000;
}

#stage .focus-plane .slope-shape-label-group line {
	stroke: #000;
}

#stage .focus-plane .slope-shape-label-group.inverted text {
	fill: #fff;
}

#stage .focus-plane .slope-shape-label-group.inverted line {
	stroke: #fff;
}

/* Hide peak label on short mobile devices */
@media screen and (max-height: 600px) {
	#stage.is-mobile .focus-plane .slope-shape-label-group.peak {
		display: none;
	}
}

/* Federal Debt Label */

#stage .focus-plane .federal-debt-label-group {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

#stage .focus-plane .federal-debt-label-group text {
	font-family: "basis-grotesque-mono", monospace;
	font-size: 1.0625em;
	fill: #000;
}

#stage.is-mobile .focus-plane .federal-debt-label-group text {
	font-size: 1em;
}

#stage .focus-plane .federal-debt-label-group .title {
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
	font-size: 1em;
	opacity: 0.8;
}

/* Hide debt label on short mobile devices */
@media screen and (max-height: 400px) {
	#stage .focus-plane .federal-debt-label-group {
		display: none;
	}
}

#tour #jump-to-menu {
	position: absolute;
	left: 0;
	width: 100%;
	background: white;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

@media (min-height: 1000px) {
	#tour #jump-to-menu.is-desktop {
		justify-content: center;
	}
}

#tour #jump-to-menu .menu-rows-wrapper {
	max-width: 1145px;
}

#tour #jump-to-menu.is-desktop {
	padding: 40px;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
}

#tour #jump-to-menu.is-mobile {
	padding: 40px 20px 20px 20px;
	top: var(--main-menu-mobile-height);
	height: calc(100vh - var(--main-menu-mobile-height));
}

@supports(padding: max(0px)) {
	/* iPhone X */
	#tour #jump-to-menu.is-mobile {
		padding-left: max(20px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}

#tour #jump-to-menu .menu-row {
	margin-bottom: 4vh;
	width: 100%;
}

#tour #jump-to-menu.is-desktop .menu-row {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
}

#tour #jump-to-menu .title-icon-wrapper {
	flex: 1 1 auto;
}

#tour #jump-to-menu.is-desktop .title-icon-wrapper {
	flex: 0 0 auto;
	width: 270px;
	margin-right: 30px;
	padding-top: 20px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-around;
}

#tour #jump-to-menu.is-mobile .title-icon-wrapper {
	margin-bottom: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

#tour #jump-to-menu .title-icon-wrapper .title {
	flex: 1 0 auto;
	font-size: 1.375em;
	-webkit-font-smoothing: antialiased;
}

#tour #jump-to-menu .title-icon-wrapper .icon {
	margin: 10px 25px 15px 0;

	width: 30px;
	height: 30px;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-end;
}

/* Section Menu */

#tour #jump-to-menu .menu-row .section-menu {
	width: 100%;
}

#tour #jump-to-menu.is-desktop .section-menu {
	flex: 1 1 auto;
}

#tour #jump-to-menu .section-menu .section-menu-row {
	display: flex;
	flex-flow: row wrap;
}

#tour #jump-to-menu.is-mobile .section-menu .section-menu-row {
	justify-content: space-between;
}

#tour #jump-to-menu a.jump-button {
	position: relative;
	display: block;

	font-family: 'basis-grotesque-medium';
	-webkit-font-smoothing: antialiased;
	font-size: 0.875em;
	color: inherit;

	margin-top: 2px; margin-bottom: 20px;

	background: transparent;
	border: 2px solid black;
	cursor: pointer;

	text-decoration: none;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #jump-to-menu.is-desktop a.jump-button {
	padding: 25px 15px;
	margin: 20px 20px 0 0;
}

#tour #jump-to-menu.is-mobile a.jump-button {
	padding: 25px 5px; width: calc(50% - 10px);
}

#tour #jump-to-menu a svg {
	display: none;
	position: absolute;
	top: -2px; left: calc(50% - 11px);
	fill: inherit;
}

#tour #jump-to-menu a.is-here svg {
	display: block;
}

#tour #jump-to-menu a.jump-button:hover {
	background: black;
	color: white;
}

#tour #jump-to-menu a.jump-button:hover svg {
	fill: white;
}

#tour #jump-to-menu.is-mobile a.is-divider {
	min-width: 100%;
}

#tour #jump-to-menu a.jump-button.is-visited {
	opacity: 0.6;
}

#tour #jump-to-menu a.jump-button.is-visited.is-here {
	opacity: 1;
}

/* Taxes Overrides */

#tour #jump-to-menu .menu-row.taxes {
	color: var(--green-color);
	fill: var(--green-color);
}

#tour #jump-to-menu .menu-row.taxes .title-icon-wrapper {

}

#tour #jump-to-menu .menu-row.taxes a.jump-button {
	border: 2px solid var(--green-color);
	color: var(--green-color);
}

#tour #jump-to-menu .menu-row.taxes a.jump-button:hover {
	color: white;
	border: 2px solid var(--green-color);
	background: var(--green-color);
}

/* Spending Overrides*/

#tour #jump-to-menu .menu-row.spending {
	color: var(--blue-color);
	fill: var(--blue-color);
}

#tour #jump-to-menu .menu-row.spending .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.spending a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--blue-color);
}

#tour #jump-to-menu .menu-row.spending a.jump-button:hover {
	color: white;
	border: 2px solid var(--blue-color);
	background: var(--blue-color);
}

/* Economy Overrides */
#tour #jump-to-menu .menu-row.economy {
	color: var(--teal-color);
	fill: var(--teal-color);
}

#tour #jump-to-menu .menu-row.economy .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.economy a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--teal-color);
}

#tour #jump-to-menu .menu-row.economy a.jump-button:hover {
	color: white;
	border: 2px solid var(--teal-color);
	background: var(--teal-color);
}

/* Give Up Overrides */

#tour #jump-to-menu .menu-row.give-up {
	color: var(--red-color);
	fill: var(--red-color);
}

#tour #jump-to-menu .menu-row.give-up .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.give-up a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--red-color);
}

#tour #jump-to-menu .menu-row.give-up a.jump-button:hover {
	color: white;
	border: 2px solid var(--red-color);
	background: var(--red-color);
}


#tour #main-menu #main-menu-bar {
	background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
}

/* Right */

#tour #main-menu #main-menu-bar .right {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

#tour #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

#tour #main-menu.is-desktop #main-menu-bar .right .jump-to-btn {
	margin-right: 30px;
}

#tour #main-menu.is-mobile #main-menu-bar .right .jump-to-btn {
	margin-right: 20px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .progress-indicator-wrapper {
	display: block;
	width: 30px; height: 30px;
	margin-right: 12px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .progress-indicator-wrapper .CircularProgressbar-trail {
  stroke: #DEDEDE;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn .jump-to-label  {
	margin-right: 10px;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn svg.jump-to-icon  {
	fill: none;
	stroke: black;
	transition: transform 200ms ease-out;
	animation-direction: reverse;
}

#tour #main-menu #main-menu-bar .right .jump-to-btn svg.flipped {
	transform: rotate(180deg); 
}

#tour #main-menu #main-menu-bar .right .divider {
	display: inline-block;
	width: 1px;
	height: 26px;
	background: rgba(0, 0, 0, 0.5);
}

#tour #main-menu #main-menu-bar .right .build-btn {
	margin-left: 30px;
}

/* scroll indicator */

#tour .scroll-indicator {
	position: -webkit-sticky;
	position: sticky;

	bottom: 0; left: 0;

	height: var(--scroll-indicator-bar-height); width: 100%;
	line-height: var(--scroll-indicator-bar-height);

	font-family: 'basis-grotesque-medium';
	-webkit-font-smoothing: antialiased;
	background: transparent;
	color: white;
	font-size: 1.125em;

	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

#tour.is-mobile .scroll-indicator {
	height: var(--scroll-indicator-bar-mobile-height); width: 100%;
	line-height: var(--scroll-indicator-bar-mobile-height);
	font-size: 0.875em;

	/* iPhone X */
	height: calc(var(--scroll-indicator-bar-mobile-height) + env(safe-area-inset-bottom));
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour .scroll-indicator .scroll-indicator-icon {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	opacity: 1.0;
	transition: opacity 500ms ease;
}

#tour .scroll-indicator.is-hidden .scroll-indicator-icon {
	opacity: 0;
}

#tour.is-desktop .scroll-indicator .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-width);
}

#tour.is-mobile .scroll-indicator .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-mobile-height);
	width: var(--scroll-indicator-bar-mobile-height);
}

#tour .scroll-indicator .scroll-indicator-icon svg {
	display: block;
	stroke: white;
	transition: stroke 0.5s ease-in-out;
}

#tour.is-desktop .scroll-indicator-icon svg {
	width: 23px; height: 37px;
}

#tour.is-mobile .scroll-indicator-icon svg {
	width: 15.5px; height: 25px;
}

@keyframes bounce-scroll-indicator {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-5px);
	}
}

#tour .scroll-indicator .scroll-indicator-icon.should-bounce {
	animation-name: bounce-scroll-indicator;
	animation-duration: 1.8s;
	animation-iteration-count: 3;
}

#tour .scroll-indicator .scroll-indicator-label {
	pointer-events: none;
	opacity: 1.0;
	transition: opacity 500ms ease;
}

#tour .scroll-indicator.is-hidden .scroll-indicator-label {
	opacity: 0;
}

#tour.is-desktop .scroll-indicator .scroll-indicator-label {
	margin-left: var(--scroll-indicator-bar-width);
}

#tour.is-mobile .scroll-indicator .scroll-indicator-label {
	margin-left: var(--scroll-indicator-bar-mobile-height);
}

/* Animated Illustrations */

#tour.is-mobile .animated-illustration-wrapper {
	height: 100%;

	-webkit-backface-visibility: hidden;

	        backface-visibility: hidden;
	transform-style: preserve-3d;
}

#tour img.animated-illustration {
	opacity: 0;
	transform: translate3d(2vw, 0, 0);
	transition: opacity 640ms ease, transform 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#tour.is-landscape img.animated-illustration {
	transform: translate3d(2vw, 0, 0);
}

#tour.is-landscape img.animated-illustration.is-flipped {
	transform: translate3d(-2vw, 0, 0);
}

#tour.is-portrait img.animated-illustration {
	transform: translate3d(0, 2vh, 0);
}

#tour.is-portrait img.animated-illustration.is-flipped {
	transform: translate3d(0, 2vh, 0);
}

#tour img.animated-illustration.is-in-view {
	opacity: 1;
	transform: translate3d(0, 0, 0)!important;
}
#tour #intro-splash-wrapper {
	background: #ffb34d;
	color: #000;
}

#tour #intro-splash-wrapper .scroll-indicator .scroll-indicator-icon svg {
	stroke: black;
}

#tour #intro-splash .tooltip {
	border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

#tour #intro-splash p {
	max-width: 500px;
}

/* split layout */

#tour #intro-splash .split-layout {
	display: flex;
}

#tour.is-desktop #intro-splash .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
}

#tour.is-mobile #intro-splash .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
}

/* Disable vcenter for mobile because square splash image 
has a lot of white space at the top */
#tour.is-mobile #intro-splash.v-center {
	align-items: flex-start;
}

#tour #intro-splash .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column:first-child {
	order: 2;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column:last-child {
	order: 1;
	flex: 0 1 auto;
	height: 50vh;
	position: relative;
}

#tour.is-desktop #intro-splash .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 600px;
}

#tour.is-mobile #intro-splash .split-layout .split-layout-column img {
	display: block;
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}
#tour .background-graph svg {
  overflow: visible;
}

#tour .background-graph .background-graph-label-group text {
  font-family: "basis-grotesque-mono", monospace;
  -webkit-font-smoothing: antialiased;
	font-size: 0.85em;
	fill: rgba(255, 255, 255, 0.8);
}

#tour .background-graph .background-graph-label-group line {
	stroke: rgba(255, 255, 255, 0.8);
}

/* Hide peak label on short mobile devices */
@media screen and (max-height: 850px) {
	#tour.is-mobile .background-graph .background-graph-label-group.top {
		display: none;
	}
}
#tour #intro-landing-wrapper {
	background: var(--debt-gradient-end-color);
	color: #fff;
	position: relative;
}

#tour #intro-landing {
	background: #242424;
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #intro-landing .content {
	z-index: 1;
	min-width: 100%;
}

#tour.is-mobile.is-portrait #intro-landing .content {
	min-height: 60vh;
}

#tour #intro-landing .content p {
	max-width: 500px;
}

#tour #intro-landing-wrapper .background-graph {
	position: absolute;
	bottom: 0; left: 0;
	pointer-events: none;
}

#tour #intro-landing-wrapper .background-graph svg {
	display: block;
	fill: url(#flame-gradient);
}

/*  Overrides */

#tour #intro-landing-wrapper .scroll-indicator svg {
	stroke: var(--red-color);
}

#tour #intro-landing-wrapper .scroll-indicator {
	color: black;
	background-image: none;
	background-color: white;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group text {
	fill: #000;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.top text {
	fill: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.top line {
	stroke: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.goal text {
	fill: #fff;
}

#tour #intro-landing-wrapper .background-graph .background-graph-label-group.goal line {
	stroke: #fff;
}

/* Menu */

#tour .options-menu {
	margin-top: 30px;
}

#tour .options-menu-row {
	display: flex;
	flex-flow: row wrap;
}

#tour .options-menu a {
	text-decoration: none;
}

#tour .options-menu .btn {
	height: 60px;
	color: #fff;
	font-size: 0.875em;

	margin: 20px 20px 0 0;
	padding: 0 36px 0 0;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	transition: background-color 150ms ease-out;
}

#tour .options-menu .btn .icon {
	flex: 0 0 auto;

	display: block;
	width: 64px; height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#tour .btn .icon svg {
	fill: white;
}

#tour .options-menu .btn .label {
	flex: 1 1 auto;
	white-space: nowrap;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
}

#tour .options-menu .btn:last-child {
	margin-right: 0;
}

/* - mobile */

#tour.is-mobile .options-menu .btn {
	width: 100%;
	margin-right: 0;
}

#tour.is-mobile .options-menu .btn .icon {
	width: 100px;
}

/* Taxes Btn */

#tour .options-menu .taxes.btn {
	background: var(--green-color);
	border: 2px solid var(--green-color);
}

#tour .options-menu .taxes.btn:hover {
	background: white;
}

#tour .options-menu .taxes.btn:hover .icon svg {
	fill: var(--green-color);
}

#tour .options-menu .taxes.btn:hover .label {
	color: var(--green-color);
}

/* Spending Btn */

#tour .options-menu .spending.btn {
	background: var(--blue-color);
	border: 2px solid var(--blue-color);
}

#tour .options-menu .spending.btn:hover {
	background: white;
}

#tour .options-menu .spending.btn:hover .icon svg {
	fill: var(--blue-color);
}

#tour .options-menu .spending.btn:hover .label {
	color: var(--blue-color);
}

/* Economy Btn */

#tour .options-menu .economy.btn {
	background: var(--teal-color);
	border: 2px solid var(--teal-color);
}

#tour .options-menu .economy.btn:hover {
	background: white;
}

#tour .options-menu .economy.btn:hover .icon svg {
	fill: var(--teal-color);
}

#tour .options-menu .economy.btn:hover .label {
	color: var(--teal-color);
}

/* Conclusion Btn */

#tour .options-menu .conclusion.btn {
	background: var(--red-color);
	border: 2px solid var(--red-color);
}

#tour .options-menu .conclusion.btn:hover {
	background: white;
}

#tour .options-menu .conclusion.btn:hover .icon svg {
	fill: var(--red-color);
}

#tour .options-menu .conclusion.btn:hover .label {
	color: var(--red-color);
}
#tour #intro-start {
	background: white;

	display: flex;
	align-items: center;

	padding: 0 var(--scroll-indicator-bar-width);
}


#tour .sticky-sidebar-wrapper.is-desktop {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
}

#tour .sticky-sidebar-wrapper.is-desktop  .sticky-sidebar-content {
	order: 1;
}

#tour .sticky-sidebar-wrapper.is-desktop .sticky-sidebar {
	order: 2;
	position: -webkit-sticky;
	position: sticky;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
	overflow: hidden;
	z-index: 1;
}

/* Mobile */

#tour .sticky-sidebar-wrapper.is-mobile {
	position: relative;

	display: flex;
	flex-flow: column nowrap;
	min-height: calc(100vh);
}

#tour .sticky-sidebar-wrapper.is-mobile .sticky-sidebar {
	flex: 0 0 auto;
	position: -webkit-sticky;
	position: sticky;
	top: var(--main-menu-mobile-height);
	height: var(--sticky-sidebar-mobile-height); width: 100%;
	z-index: 1;
	overflow: hidden;
}

#tour .sticky-sidebar-wrapper.is-mobile .sticky-sidebar-content {
	flex: 0 0 auto;
	position: relative;
	min-height: calc(100vh - var(--main-menu-mobile-height) - var(--sticky-sidebar-mobile-height));
}

/*  Scroll Anchor Overrides */

#tour .sticky-sidebar-wrapper.is-desktop .scroll-anchor-impl {
	top: calc(var(--main-menu-height) * -1); 
	left: 0;
	height: var(--main-menu-height);
}

#tour .sticky-sidebar-wrapper.is-mobile .scroll-anchor-impl {
	top: calc(((var(--sticky-sidebar-mobile-height) + var(--main-menu-mobile-height)) - 20px) * -1); 
	left: 0;
	height: calc(var(--sticky-sidebar-mobile-height) + var(--main-menu-mobile-height) - 20px);
}

#tour .gdp-graph {
	position: absolute;
	height: 100%; width: 100%;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	background: inherit;
	color: inherit;

	opacity: 1.0;
	transition: opacity 400ms ease-in;
	transition-delay: 200ms;	
	transform: translate3d(0,0,0);
}

#tour .gdp-graph.is-hidden {
	transition: opacity 200ms linear;
	opacity: 0;
}

/* graph */
#tour .gdp-graph .graph {
	position: absolute;
	background: #262222;
}

#tour .gdp-graph.is-desktop .graph {
	right: 0; top: 0;
	height: 100%; width: 80%;
}

#tour .gdp-graph.is-mobile .graph {
	left: 0; top: 0;
	width: 100%; height: calc(100% - var(--gdp-graph-mobile-legend-height))
}

/* -scale 100 */
#tour .gdp-graph .graph .scale-100 {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .scale-100 {
	height: 50%; width: 100%;
	border-top: 1px solid white;
}

#tour .gdp-graph.is-mobile .graph .scale-100 {
	height: 100%; width: 50%;
	border-right: 1px solid white;
}

/* -scale 195 (97.5%) */
#tour .gdp-graph .graph .scale-195 {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .scale-195 {
	border-top: 1px solid white;
	height: 97.5%; width: 100%;
}

#tour .gdp-graph.is-mobile .graph .scale-195 {
	border-right: 1px solid white;
	height: 100%; width: 97.5%;
}

/* -bar target */
#tour .gdp-graph .graph .bar-target {
	position: absolute;
	bottom: 0; left: 0;
}

#tour .gdp-graph.is-desktop .graph .bar-target {
	width: 100%;
	background-image: linear-gradient(-180deg, var(--debt-gradient-start-color) 0%, var(--debt-gradient-start-color) 15%, var(--debt-gradient-end-color) 100%);
}

#tour .gdp-graph.is-mobile .graph .bar-target {
	height: 100%;
	text-align: right;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: flex-end;

	background-image: linear-gradient(-90deg, var(--debt-gradient-start-color) 0%, var(--debt-gradient-start-color) 30%, var(--debt-gradient-end-color) 100%);
}

#tour .gdp-graph .graph .bar-target .label {
	color: black;
}

#tour .gdp-graph.is-desktop .graph .bar-target .label {
	margin: 15px;
	font-size: 0.875em;
}

#tour .gdp-graph.is-mobile .graph .bar-target .label {
	order: 1;
	padding: 5px;
	display: block;
	transform: translateX(100%) rotate(270deg);
	transform-origin: left bottom 0;
	font-size: 0.625em;
}

/* -bar target difference*/

#tour .gdp-graph .graph .bar-target .difference {
	position: relative;
	background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAFElEQVR4AWPAAf5D8MBLYEriEgcAc54N8+z5QMoAAAAASUVORK5CYII=);
}

#tour .gdp-graph.is-desktop .graph .bar-target .difference {
	position: relative;
	top: 0; left: 0;
	width: 100%; height: 30%;
	transition: height 400ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

#tour .gdp-graph.is-mobile .graph .bar-target .difference {
	order: 2;
	position: relative;
	top: 0; right: 0;
	width: 30%; height: 100%;
	transition: width 400ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

/* legend */

#tour .gdp-graph .legend {
	position: absolute;
	font-size: 0.75em;
	line-height: 1.2em;
}

#tour .gdp-graph.is-desktop .legend {
	top: 0; left: 0;
	width: 20%; height: 100%;
}

#tour .gdp-graph.is-mobile .legend {
	left: 0; bottom: 0;
	width: 100%; height: var(--gdp-graph-mobile-legend-height);
}

#tour .gdp-graph .legend .label {
	position: absolute;
}

#tour .gdp-graph.is-desktop .legend .label {
	width: 100%;
	text-align: right;
	padding-right: 10px;
	margin-top: -6px; /* v center */
	transition: top 430ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* we don't animate labels on mobile */
}

#tour .gdp-graph.is-desktop .legend .label.hidden {
	opacity: 0;
	transition: opacity 400ms ease;
}

#tour .gdp-graph.is-mobile .legend .label {
	position: absolute;
	height: 100%;
	vertical-align: middle;
	margin-top: 10px;
}

#tour .gdp-graph.is-desktop .legend .label.scale-100 {
	top: 50%;
}

#tour .gdp-graph.is-desktop .legend .label.scale-195 {
	top: 2.5%;
}

#tour .gdp-graph.is-mobile .legend .label.scale-100 {
	right: 50%;
}

#tour .gdp-graph.is-mobile .legend .label.scale-195 {
	right: 5%;
}

#tour .gdp-graph.is-mobile .legend .label.target {
	opacity: 0;
}

#tour-intro-page {
	background: white;
}

#tour-intro-page .gdp-graph {
	color: black;
}

/* overrides */

/* - gdp graph*/

#tour #tour-intro-page .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, #fff 0%, rgba(255, 255, 255, 0.00) 100%);
}

#tour #main-side-menu {
  width: var(--main-side-menu-width);
  height: calc(100vh - var(--main-menu-height));
  position: fixed;
  top: var(--main-menu-height);
  left: 0;
  z-index: 9;
  overflow: hidden;
}

#tour.is-mobile #main-side-menu {
  display: none;
}

#tour #main-side-menu ul {
  list-style: none;
}

#tour #main-side-menu ul li {
  width: 42px;
  height: calc((100vh - var(--main-menu-height)) / 4);
}

#tour #main-side-menu ul li.raise-taxes {
  background: var(--green-color);
}

#tour #main-side-menu ul li.cut-spending {
  background: var(--blue-color);
}

#tour #main-side-menu ul li.grow-the-economy {
  background: var(--teal-color);
}

#tour #main-side-menu ul li.a-way-forward {
  background: var(--red-color);
}

#tour #main-side-menu ul li a {
  display: block;
  padding: 0;
  text-decoration: none;
}

#tour #main-side-menu ul li .label {
  transform: rotate(-90deg) translateX(calc(((100vh - var(--main-menu-height)) / 4) * -1));
  transform-origin: top left;

  width: calc((100vh - var(--main-menu-height)) / 4);
  height: 42px;

  text-align: center;
  padding: 12px 4px 0 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "basis-grotesque-medium";
  -webkit-font-smoothing: antialiased;
  font-size: 0.875em;
  color: white;
  transition: opacity 100ms ease-out;
}

#tour #main-side-menu ul li .label:hover {
  opacity: 0.6;
}

/* #tour #main-side-menu ul li:first-child .label {
  margin-top: calc((100vh - var(--main-menu-height)) / 4);
} */

#tour #calculator-landing-wrapper {
  background: var(--calculator-blue-color);
	color: #000;
	overflow: hidden;
}

#tour #calculator-landing p {
	max-width: 500px;
}

/* Split Layout */

#tour #calculator-landing .split-layout {
	display: flex;
}

#tour.is-desktop #calculator-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #calculator-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
  width: 100%;
}

#tour #calculator-landing .split-layout .split-layout-column:first-child {
  flex: 0 1 auto;
}

#tour.is-desktop #calculator-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
	max-width: 50vw;
}

#tour.is-desktop #calculator-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
  justify-content: center;
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column:first-child {
	order: 2;
	padding: 0 20px 20px 20px;
	/* iPhone X */
	padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column:last-child {
	order: 1;

	flex: 0 1 auto;
	height: 50vh;
	position: relative;
	width: 100%;
}

#tour #calculator-landing .split-layout .split-layout-column img {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column img {
	display: block;
	max-height: 100%;
	width: auto;
	max-width: 100%;
}

/* Overrides */

#tour #calculator-landing .picker-menu .picker-menu-btn {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-top: 45px;
	border-color: #000;
  color: #000;
}

#tour.is-mobile #calculator-landing .picker-menu .picker-menu-btn {
	margin-top: 20px;
}

#tour #calculator-landing .picker-menu a {
  text-decoration: none;
}

#tour #calculator-landing .picker-menu .picker-menu-btn:hover {
	background: black;
	color: var(--calculator-blue-color);
}

#tour #calculator-landing .picker-menu .picker-menu-btn .next-arrow-icon {
	stroke: #fff;
}

#tour #calculator-landing .picker-menu .picker-menu-btn:hover .next-arrow-icon {
	stroke: #000;
}

#tour #calculator-landing .picker-menu .picker-menu-btn .label {
	margin-right: 10px;
}


#tour #tour-conclusion-page {
	background: var(--red-color);
	color: white;
}

#tour.is-desktop #tour-conclusion-page {
	margin-left: var(--main-side-menu-width);
}

#tour #tour-conclusion-landing p {
	max-width: 600px;
}

/* Split Layout */

#tour #tour-conclusion-landing .split-layout {
	display: flex;
}

#tour.is-desktop #tour-conclusion-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 40px 20px 20px 20px;
}

#tour #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #tour-conclusion-landing .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
}

#tour.is-desktop #tour-conclusion-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #tour-conclusion-landing .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 600px;
}

/* Conclusion Content */

#tour.is-mobile #tour-conclusion-content h1 {
	margin-top: 5vh;
}

#tour #tour-conclusion-content p {
	max-width: 550px;
}


#tour #tour-conclusion-content .split-layout {
	display: flex;
}

#tour.is-desktop #tour-conclusion-content .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	max-width: 1600px;
	width: 100%;
	margin: auto;
}

#tour.is-mobile #tour-conclusion-content .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 20px;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column {
	flex: 1 1 auto;
	width: 50%;
}

#tour.is-mobile #tour-conclusion-content .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-desktop #tour-conclusion-content .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #tour-conclusion-content .split-layout .split-layout-column img  {
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0;
	padding: 0;
}

/* Animated Illustrations */

#tour #tour-conclusion-page .split-layout .split-layout-column.illustration {
	overflow: hidden;
}

#tour.is-mobile #tour-conclusion-page .split-layout .split-layout-column.illustration {
	height: 40vh;
	position: relative;
	width: 100%;
}


#tour.is-mobile.is-landscape #tour-conclusion-page .split-layout .split-layout-column.illustration {
	height: 70vh;
}

#tour.is-mobile #tour-conclusion-page .split-layout .split-layout-column.illustration img  {
	display: block;
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column:first-child {
	order: 1;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column:last-child {
	order: 2;
}


#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column:first-child {
	order: 2;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column:last-child {
	order: 1;
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout .split-layout-column.copy {
	margin-left: var(--scroll-indicator-bar-width);
}

#tour.is-desktop.is-landscape #tour-conclusion-content .split-layout.flipped .split-layout-column.copy {
	margin-left: 0;
}

/* Overrides */

#tour #tour-conclusion-page .scroll-indicator {
	background-image: linear-gradient(0deg, var(--red-color) 0%, var(--red-zero-alpha-color) 100%);
}

#tour #tour-conclusion-page .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--red-color) 0%, var(--red-zero-alpha-color) 100%);
}
#tour #taxes-landing {
	padding: 0 7%;
	background: var(--green-color);
}

#tour #taxes-landing-wrapper {
	background: var(--dark-green-color);
}

#tour #taxes-landing .content {
	z-index: 1;
}

#tour.is-mobile.is-portrait #taxes-landing .content {
	min-height: 60vh;
}

#tour #taxes-landing h2 {
	max-width: 500px;
}

#tour #taxes-landing p {
	max-width: 600px;
}

#tour #taxes-landing .background-graph {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
}

#tour #taxes-landing .background-graph svg {
	fill: var(--dark-green-color);
	display: block;
}

/*  overrides */

#tour #tour-taxes-container #taxes-landing-wrapper .scroll-indicator {
	background-image: none;
	border-top: 2px solid var(--green-color);
}
#tour .tour-poll-container {
	transition: background 150ms ease;
}

#tour .poll {
	margin: 45px 0 0 0;
}

#tour .poll li {
	font-size: 2.5em;

	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;

	display: flex;
	align-items: center;
}

#tour.is-mobile .poll li {
	font-size: 1.875em;
}

#tour .poll li + li {
	margin-top: 30px;
}

#tour.is-mobile .poll li + li {
	margin-top: 30px;
}

#tour .poll input[type="radio"] {
	position: relative;
	display: inline-block;
	cursor: pointer;
	-webkit-appearance: none;

	background: transparent;
	transition: background-color 150ms ease-out;

	border: 2px solid white;
	border-radius: 20px;
	height: 20px; width: 20px;
	outline: none;
}

#tour .poll input[type="radio"]:checked {
	background: white;
}

#tour .poll label {
	cursor: pointer;
	padding-left: 20px;
	font-family: 'basis-grotesque-medium';
}

#tour .picker-menu.is-desktop {
	margin: 20px 0;
}

#tour .picker-menu.is-mobile {
	margin: 10px 0;
}

#tour .picker-menu.is-desktop {
	max-width: 800px;
}

#tour .picker-menu .picker-menu-btn {
	background: transparent;
	transition: background-color 120ms ease-out, border 120ms ease-out;
	border: 0;
	color: white;
	border: 2px solid white;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
	font-size: 0.875em;
	padding: 25px 15px;
	margin: 20px 20px 0 0;

	cursor: pointer;
	outline: none;
}

#tour .picker-menu.is-mobile .picker-menu-btn {
	width: 100%;
}

#tour .picker-menu .picker-menu-btn:hover {
	background: white;
	color: black; /* intended to be overwritten */
}

#tour .picker-menu .picker-menu-btn.is-visited {
	opacity: 0.5;
}

/* Skip to Spending */

#tour .picker-menu .picker-menu-btn.is-skip-btn.spending {
	background: var(--blue-color);
	border: 2px solid var(--blue-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.spending:hover {
	background-color: white;
	color: var(--blue-color)!important;
	border: 2px solid white;
}


/* Skip to Economy */

#tour .picker-menu .picker-menu-btn.is-skip-btn.economy {
	background: var(--teal-color);
	border: 2px solid var(--teal-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.economy:hover {
	background-color: white;
	color: var(--teal-color)!important;
	border: 2px solid white;
}

/* Skip to A Way Forward */

#tour .picker-menu .picker-menu-btn.is-skip-btn.a-way-forward {
	background: var(--red-color);
	border: 2px solid var(--red-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.a-way-forward:hover {
	background-color: white;
	color: var(--red-color)!important;
	border: 2px solid white;
}




#tour #tax-everyone-answer ul {
	margin: 10% 0;
}

#tour #tax-everyone-answer li {
	display: flex;
	flex-flow: row no-wrap;
	align-items: center;
}

#tour #tax-everyone-answer li + li {
	margin-top: 4%;
}

#tour #tax-everyone-answer .icon {
	flex: 0 0 auto;
	display: block;
	width: 40px;
	height: 30px;
	margin-right: 20px;
}

#tour #keep-in-mind .content {
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #keep-in-mind h2 {
	max-width: 500px;
}

#tour #keep-in-mind p {
	max-width: 600px;
}
#tour #tour-taxes-container {
	background: var(--green-color);
	color: white;
}

#tour.is-desktop #tour-taxes-container {
	margin-left: var(--main-side-menu-width);
}

/* overrides */

/* - tour poll component  */

#tour #tour-taxes-container .tour-poll-container {
	background: var(--green-color);
	color: white;
}

#tour #tour-taxes-container .tour-poll-container.is-wrong {
	background: var(--gray-color);
}

/* - picker menu */

#tour #tour-taxes-container .tour-poll-container .picker-menu .picker-menu-btn:hover {
	color: var(--green-color);
}

#tour #tour-taxes-container .tour-poll-container.is-wrong .picker-menu .picker-menu-btn:hover {
	color: var(--gray-color);
}

/* - gdp graph */

#tour #tour-taxes-container .tour-poll-container .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--green-color) 0%, var(--green-color-zero-alpha) 100%);
}

#tour #tour-taxes-container .tour-poll-container.is-wrong .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--gray-color) 0%, var(--gray-zero-alpha-color) 100%);
}

/* - scroll indicator */

#tour #tour-taxes-container .scroll-indicator {
	background-image: linear-gradient(0deg, var(--green-color) 0%, var(--green-color-zero-alpha) 100%);
}

#tour #tour-taxes-container .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--dark-green-color) 0%, var(--dark-green-zero-alpha-color) 100%);
}
/* Overrides */

#tour #tour-taxes-container .picker-menu.is-mobile {
  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}
#tour .accordion {
  margin-top: 20px;
}

#tour.is-desktop .accordion {
  max-width: 750px;
}

#tour .accordion .accordion-row + .accordion-row {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

#tour .accordion .accordion-row .header {
  display: block;
  width: 100%;

  padding: 15px 0 20px 0;
  border: 0;
  cursor: pointer;
  background: transparent;
  color: inherit;

	font-size: inherit;
	line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;

  text-align: left;
  outline: 0;
}

#tour .accordion .accordion-row .header h3 {
	font-size: 1.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour.is-mobile .accordion .accordion-row .header h3 {
	font-size: 1.25em;
}

#tour .accordion .accordion-row .header .label {
  width: 100%;
  max-width: 750px;

  display: flex;
  flex-flow: row nowrap;
}

#tour .accordion .accordion-row .header .label .disclosure-indicator {
  display: block;
  width: 30px;
}

#tour .accordion .accordion-row .header .label .title {
  display: block;
}

#tour .accordion .accordion-row .body {
  opacity: 0;
  transition: opacity 450ms 100ms ease;
  overflow: hidden;
  max-width: 600px;
}

#tour .accordion .accordion-row.is-collapsed .body {
  height: 0;
}

#tour .accordion .accordion-row.is-expanded .body {
  height: auto;
  opacity: 1;
  padding: 0 0 20px 30px;
}
#tour #close-loopholes-page {
	background: var(--green-color);
	color: white;
}

#tour.is-mobile #close-loopholes-landing h2 {
	margin-top: var(--main-menu-mobile-height);
}

#tour #close-loopholes-landing p {
	max-width: 600px;
}
/* overrides */

#tour #close-loopholes-page .scroll-indicator {
	color: white;
	background-image: linear-gradient(-180deg, var(--green-color-zero-alpha) 0%, var(--green-color) 100%);
}


/* split layout */

#tour #close-loopholes-landing .split-layout {
	display: flex;
}

#tour.is-desktop #close-loopholes-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #close-loopholes-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
	min-height: 100vh;
}

#tour #close-loopholes-landing .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #close-loopholes-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #close-loopholes-landing .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #close-loopholes-landing .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
}

#tour.is-desktop #close-loopholes-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #close-loopholes-landing .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 500px;
}
#tour #spending-landing-wrapper {
	color: white;
	background: var(--dark-blue-color);
}

#tour #spending-landing {
	padding: 0 var(--scroll-indicator-bar-width);
	background: var(--blue-color);
}

#tour #spending-landing .content {
	z-index: 1;
}

#tour.is-mobile.is-portrait #spending-landing .content {
	min-height: 60vh;
}

#tour #spending-landing h2 {
	max-width: 700px;
}

#tour #spending-landing p {
	max-width: 600px;
}


#tour #spending-landing .background-graph {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
}

#tour #spending-landing .background-graph svg {
	fill: var(--dark-blue-color);
	display: block;
}

/*  overrides */

#tour #tour-spending-container #spending-landing-wrapper .scroll-indicator {
	background-image: none;
	border-top: 2px solid var(--blue-color);
}
#tour #what-do-we-mean-by-cuts h2 {
	max-width: 500px;
}

#tour #what-do-we-mean-by-cuts p {
	max-width: 600px;
}


/* split layout */

#tour #what-do-we-mean-by-cuts .split-layout {
	display: flex;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px;
	min-height: 100vh;
}

#tour #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	flex: 0 1 auto;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout .split-layout-column:first-child {
	margin-bottom: 40px;
}

#tour.is-mobile #what-do-we-mean-by-cuts .split-layout .split-layout-column:last-child {
	flex: 0 1 auto;
	width: 100%;
}

#tour.is-desktop #what-do-we-mean-by-cuts .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #what-do-we-mean-by-cuts .split-layout .split-layout-column img {
	display: block;
	width: 100%;
	max-width: 900px;
}
#tour .spending-graph {
	position: absolute;
	height: 100%; width: 100%;

	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;

	background: inherit;
	color: inherit;

	opacity: 1.0;
	transition: opacity 400ms ease-in;
	transition-delay: 200ms;	
	transform: translate3d(0,0,0);
}

#tour .spending-graph.is-hidden {
	transition: opacity 200ms linear;
	opacity: 0;
}

/* Graph */

#tour .spending-graph .graph {
	position: absolute;
}

#tour .spending-graph.is-desktop .graph {
  right: 0; top: 0;
	height: 100%; width: 80%;
}

#tour .spending-graph.is-mobile .graph {
  left: 0; top: 0;
  height: 100%; width: 100%;
  
  display: flex;
  flex-flow: row nowrap;
}

/* - Bar Segment */

#tour .spending-graph .graph .bar-segment {
  position: relative;
  overflow: hidden;
}

#tour .spending-graph .graph .bar-segment:nth-child(1) {
  background: var(--red-color);
}
#tour .spending-graph .graph .bar-segment:nth-child(2) {
  background: #A54736;
}
#tour .spending-graph .graph .bar-segment:nth-child(3) {
  background: #763327;
}
#tour .spending-graph .graph .bar-segment:nth-child(4) {
  background: #471E17;
}
#tour .spending-graph .graph .bar-segment:nth-child(5) {
  background: #180A08;
}

#tour .spending-graph.is-desktop .graph .bar-segment {
  width: 100%;
  left: 0;
  transition: height 650ms ease;
}

#tour .spending-graph.is-mobile .graph .bar-segment {
  height: 100%;
  top: 0;
  transition: width 650ms ease;
}

#tour .spending-graph .graph .bar-segment .label {
	color: white;
}

#tour .spending-graph.is-desktop .graph .bar-segment .label {
	padding: 15px;
	font-size: 0.875em;
}

#tour .spending-graph.is-mobile .graph .bar-segment .label {
  display: block;

  width: var(--sticky-sidebar-mobile-height);
  padding: 5px;

  transform: rotate(-90deg) translateX(calc(var(--sticky-sidebar-mobile-height) * -1));
  transform-origin: top left;

  font-size: 0.75em;
}

#tour .spending-graph .graph .bar-segment .percent-label {
  transition: opacity 300ms ease;
  opacity: 0;
}

#tour .spending-graph .graph .bar-segment .percent-label.is-visible {
  opacity: 1;  
}

/* Overrides */

#tour #tour-spending-container .picker-menu.is-mobile .menu-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour #tour-spending-container .picker-menu.is-desktop .menu-row {
  display: inline-block;
}

#tour #tour-spending-container .picker-menu.is-mobile a {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 10px);
  display: block;
}

#tour #tour-spending-container .picker-menu.is-mobile .menu-row a:first-child{
  flex: 0 1 100%;
  width: 100%;
}

#tour #tour-spending-container .picker-menu.is-mobile .menu-row .picker-menu-btn {
  margin-right: 0;
}
#tour #spending-history h1 {
	font-family: "basis-grotesque-mono";
}

#tour.is-desktop #spending-history h1 {
  font-size: 12.5em;
  margin-top: 45px;
}

#tour.is-mobile #spending-history h1 {
  font-size: 7.5em;
  margin-top: 20px;
}

#tour #spending-history h2 {
	max-width: 500px;
}

#tour #spending-history p {
	max-width: 600px;
}
#tour #tour-spending-container {
	background: var(--blue-color);
	color: white;
}

#tour.is-desktop #tour-spending-container {
	margin-left: var(--main-side-menu-width);
}


#tour #tour-spending-container .what-else-prompt {
	margin-top: 25px;
}

/* overrides */

/* - tour poll component  */

#tour #tour-spending-container .tour-poll-container {
	background: var(--blue-color);
	color: white;
}

#tour #tour-spending-container .tour-poll-container.is-wrong {
	background: var(--gray-color);
}

/* - picker menu */

#tour #tour-spending-container .picker-menu .picker-menu-btn:hover {
	color: var(--blue-color);
}

#tour #tour-spending-container .tour-poll-container.is-wrong .picker-menu .picker-menu-btn:hover {
	color: var(--gray-color);
}

/* - gdp graph */

#tour #tour-spending-container .tour-poll-container .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--blue-color) 0%, var(--blue-zero-alpha-color) 100%);
}

#tour #tour-spending-container .tour-poll-container.is-wrong .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--gray-color) 0%, var(--gray-zero-alpha-color) 100%);
}

/* - scroll indicator */

#tour #tour-spending-container .scroll-indicator {
	background-image: linear-gradient(0deg, var(--blue-color) 0%, var(--blue-zero-alpha-color) 100%);
}

#tour #tour-spending-container .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--dark-blue-color) 0%, var(--dark-blue-zero-alpha-color) 100%);
}
#tour #why-is-social-security-spending-rising {
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #why-is-social-security-spending-rising h2 {
	max-width: 700px;
}

#tour #why-is-social-security-spending-rising p {
	max-width: 600px;
}

#tour #reform-medicare-landing ol {
	list-style-type: decimal;
	padding-left: 20px;
	margin-top: 6%;
}

#tour #reform-medicare-landing li {
	margin-top: 3%;
}

#tour #reform-medicare-landing ol + p {
	margin-top: 6%;
}


#tour #why-is-medicare-spending-rising {
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #why-is-medicare-spending-rising h2 {
	max-width: 700px;
}

#tour #why-is-medicare-spending-rising p {
	max-width: 600px;
}

#tour #economy-landing-wrapper {
	color: white;
	background: var(--dark-teal-color);
}

#tour #economy-landing {
	background: var(--teal-color);
}

#tour #economy-landing .content {
	z-index: 1;
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour.is-mobile #economy-landing .content {
	min-height: 60vh;
}

#tour #economy-landing h2 {
	max-width: 700px;
}

#tour #economy-landing p {
	max-width: 600px;
}

#tour #economy-landing .background-graph {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
}

#tour #economy-landing .background-graph svg {
	fill: var(--dark-teal-color);
	display: block;
}

/*  overrides */

#tour #tour-economy-container #economy-landing-wrapper .scroll-indicator {
	background-image: none;
	border-top: 2px solid var(--teal-color);
}
#tour #economy-productivity-employment h2 {
	max-width: 500px;
}

#tour #economy-productivity-employment p {
	max-width: 600px;
}

#tour #economy-productivity-employment .row-wrapper {
  width: 100%;
  margin-top: 60px;
}

#tour.is-desktop #economy-productivity-employment .row-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#tour.is-desktop #economy-productivity-employment .row-wrapper .column-50:first-child {
  margin-right: 100px;
}

#tour.is-mobile #economy-productivity-employment .row-wrapper .column-50 {
  width: 100%;
}

#tour.is-mobile #economy-productivity-employment .row-wrapper .column-50:first-child {
  margin-bottom: 30px;
}


/* Overrides */

#tour #tour-economy-container .picker-menu.is-mobile {
  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}
#tour #tour-economy-container {
	background: var(--teal-color);
  color: white;
}

#tour.is-desktop #tour-economy-container {
	margin-left: var(--main-side-menu-width);
}

#tour #tour-economy-container .what-else-prompt {
	margin-top: 25px;
}

/* overrides */

/* - tour poll component  */

#tour #tour-economy-container .tour-poll-container {
	background: var(--teal-color);
	color: white;
}

#tour #tour-economy-container .tour-poll-container.is-wrong {
	background: var(--gray-color);
}

/* - picker menu */

#tour #tour-economy-container .picker-menu .picker-menu-btn:hover {
	color: var(--teal-color);
}

#tour #tour-economy-container .tour-poll-container.is-wrong .picker-menu .picker-menu-btn:hover {
	color: var(--gray-color);
}

/* - gdp graph */

#tour #tour-economy-container .tour-poll-container .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--teal-color) 0%, var(--teal-zero-alpha-color) 100%);
}

#tour #tour-economy-container .tour-poll-container.is-wrong .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, var(--gray-color) 0%, var(--gray-zero-alpha-color) 100%);
}

/* - scroll indicator */

#tour #tour-economy-container .scroll-indicator {
	background-image: linear-gradient(0deg, var(--teal-color) 0%, var(--teal-zero-alpha-color) 100%);
}

#tour #tour-economy-container .scroll-indicator.is-dark {
	background-image: linear-gradient(0deg, var(--dark-teal-color) 0%, var(--dark-teal-zero-alpha-color) 100%);
}
#tour .illustration-sidebar-wrapper-background {
	background-color: var(--teal-color);
	width: 100%;
}

#tour .illustration-sidebar-wrapper {
	background-color: var(--teal-color);
	max-width: 2000px;
	margin: auto;
}

#tour .illustration-sidebar-wrapper.is-desktop {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
}

#tour .illustration-sidebar-wrapper.is-desktop .illustration-sidebar-content {
	order: 1;
}

#tour .illustration-sidebar-wrapper .illustration-sidebar {
  display: flex;
  align-items: center;
	justify-content: center;
}

#tour .illustration-sidebar-wrapper .illustration-sidebar img {
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0;
	padding: 0;
}

#tour .illustration-sidebar-wrapper.is-desktop .illustration-sidebar {
	order: 2;
	position: -webkit-sticky;
	position: sticky;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
	margin-right: var(--scroll-indicator-bar-width);
	overflow: hidden;
	z-index: 1;
}

/* Mobile */

#tour .illustration-sidebar-wrapper.is-mobile {
	position: relative;

	display: flex;
	flex-flow: column nowrap;
	min-height: calc(100vh);
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar {
  flex: 0 0 auto;
  position: relative;
	padding-top: var(--main-menu-mobile-height);
	height: 50vh; width: 100%;
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar img {
	max-height: 100%;
	width: auto;
	margin: 0 auto;
}

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar-content {
	flex: 0 0 auto;
	position: relative;
	min-height: calc(100vh - var(--main-menu-mobile-height) - 50vh);
}


/* Overrides */

#tour .illustration-sidebar-wrapper.is-mobile .illustration-sidebar-content .full-page-w-menu.is-first-page {
	margin-top: 0;
}
:root {
	--white-color: #fff;
	--green-color: #22A750;
	--green-color-zero-alpha: rgba(34, 167, 80, 0.00);
	--dark-green-color: #0A3117;
	--dark-green-zero-alpha-color: rgba(10, 49, 23, 0.00);
	--blue-color: #2E07A9;
	--blue-zero-alpha-color: rgba(46, 7, 169, 0.00);
	--dark-blue-color: #0D0232;
	--dark-blue-zero-alpha-color: rgba(32, 4, 117, 0.00);
	--teal-color: #14AA9A;
	--teal-zero-alpha-color: rgba(20, 170, 154, 0.00);
	--dark-teal-color: #17342F;
	--dark-teal-zero-alpha-color: rgba(23, 52, 47, 0.00);
	--red-color: #EC654D;
	--red-zero-alpha-color: rgba(236, 101, 77, 0.00);
	--gray-color: #61686C;
	--gray-zero-alpha-color: rgba(97, 104, 108, 0.00);
	--dark-gray-color: #242424;
	--dark-gray-zero-alpha-color: rgba(36, 36, 36, 0.00);
	--calculator-blue-color: #36c4ee;
	--debt-gradient-start-color: #FF5A42;
	--debt-gradient-end-color: #FFCE4F;

	--main-menu-height: 60px;
	--main-side-menu-width: 40px;
	--main-menu-mobile-height: 50px;
	--sticky-sidebar-mobile-height: 30vh;
	--gdp-graph-mobile-legend-height: 52px;
	--scroll-indicator-bar-height: 80px;
	--scroll-indicator-bar-mobile-height: 48px;
	--scroll-indicator-bar-width: 8vw;
}

body {
	-webkit-text-size-adjust: none;
}

#tour {
	font-family: "DomaineSansWebRegular";
}

.tooltip {
	border-bottom: 2px solid rgba(255, 255, 255, 0.5);
	cursor: help;
}

.tooltip-bubble {
	max-width: 300px !important;
	font-family: "basis-grotesque-mono";
	font-size: 0.875em;
	line-height: 1.3em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
	padding: 25px 20px;
}

.tooltip-bubble .multi-line {
	text-align: left !important;

}

#tour .full-page {
	position: relative;
	width: 100%;
	min-height: 100vh;

	transform:translateZ(0);
}

#tour .full-page-w-menu {
	position: relative;
	width: 100%;
	overflow-x: hidden;
	transform:translateZ(0);
}

#tour.is-desktop .full-page-w-menu {
	min-height: calc(100vh - var(--main-menu-height));
}

#tour.is-mobile .full-page-w-menu {
	min-height: calc(100vh - var(--main-menu-mobile-height));
}

#tour.is-desktop .full-page-w-menu.is-first-page {
	margin-top: var(--main-menu-height);
}

#tour.is-mobile .full-page-w-menu.is-first-page {
	margin-top: var(--main-menu-mobile-height);
}

#tour.is-mobile .full-page-w-menu.is-first-[age] {
	margin-top: var(--main-menu-mobile-height);
}

#tour.is-mobile .full-page-w-menu.w-sidebar{
	width: 100%;
	min-height: calc(100vh - var(--sticky-sidebar-mobile-height));
}

#tour.is-desktop .full-page-w-menu.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-height) - var(--main-menu-height));
}

#tour.is-mobile .full-page-w-menu.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-mobile-height) - var(--main-menu-mobile-height));
}

#tour.is-desktop .full-page.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-height));
}

#tour.is-mobile .full-page.has-scroll-indicator {
	min-height: calc(100vh - var(--scroll-indicator-bar-mobile-height));
}

/* layout */
#tour .column-70 {
	width: 67.8%;
}

#tour .column-50 {
	width: 50%;
}

#tour .column-30 {
	width: 32.2%;
}

#tour .v-center {
	display: flex;
	align-items: center;
}

/* row content */
#tour.is-desktop .row-content {
	padding: 5% var(--scroll-indicator-bar-width) 5% var(--scroll-indicator-bar-width);
	display: flex;
	align-items: center;
}

#tour.is-mobile .row-content {
	padding: 20px;
}

#tour .row-content .content h2 {
	max-width: 800px;
}

#tour .row-content .content p {
	max-width: 600px;
}

#tour .row-content .content ul.is-inset {
	margin-left: 20px;
}

#tour .row-content .content ul li {
	margin-top: 20px;
}

#tour.is-mobile .row-content .content {
	margin-top: 0px;
	max-width: 700px;
	width: 100%;
	/* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour .row-content.disabled {
	opacity: 0.6;
	transition: opacity 200ms ease;
	pointer-events: none;
}

/* Landing */

#tour .is-landing {
	padding: 10vh var(--scroll-indicator-bar-width);
}

#tour.is-mobile .is-landing .content {
	min-height: 60vh;
}
#tour .is-landing h2 {
	max-width: 700px;
}

#tour .is-landing p {
	max-width: 600px;
}

/* Scroll anchor: we use this to be able to scroll
mobile poll answers to the appropriate place
despite them scrolling behind the graph and top menu.
*/

#tour .scroll-anchor-impl {
	position: absolute;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	/* background: pink;
	opacity: 0.8; */
}

#tour.is-desktop .scroll-anchor-impl {
	top: calc(var(--main-menu-height) * -1);
	left: 0;
	height: var(--main-menu-height);
}

#tour.is-mobile .scroll-anchor-impl {
	top: calc(var(--main-menu-mobile-height) * -1);
	left: 0;
	height: var(--main-menu-mobile-height);
}


/* Text */

#tour .uppr {
	text-transform: uppercase;
}

/* Typography */

#tour a {
	color: inherit;
}

#tour h1 {
	font-size: 3.75em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour h2 {
	font-size: 2em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#tour p {
	font-size: 1.125em;
	line-height: 1.75rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
}

#tour h1 + p {
	margin-top: 45px;
}

#tour h2 + p {
	margin-top: 45px;
}

#tour p + p {
	margin-top: 15px;
}

#tour h1 + h2 {
	margin-top: 45px;
}

/* - mobile */
#tour.is-mobile h1 {
	font-size: 1.875em;
}

#tour.is-mobile h2 {
	font-size: 1.5em;
}

#tour.is-mobile p {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

#tour.is-mobile h1 + p {
	margin-top: 20px;
}

#tour.is-mobile h2 + p {
	margin-top: 20px;
}

#tour.is-mobile h1 + h2 {
	margin-top: 20px;
}
#tour {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	min-height: 100vh;
	min-width: 320px;
}
#static-pages-container #main-menu #main-menu-bar {
	background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
}

/* Right */

#static-pages-container #main-menu #main-menu-bar .right .explore-btn {
	margin-right: 30px;
}

#static-pages-container #main-menu #main-menu-bar .right .build-btn {
	margin-left: 35px;
}

@media (max-width: 600px) {
	#static-pages-container #main-menu.is-mobile .right {
		display: none;
	}

	@supports(padding: max(0px)) {
		#static-pages-container #main-menu.is-mobile #main-menu-bar {
			padding-right: max(40px, env(safe-area-inset-right));
		}
	}
}
#static-pages-container {
  padding: 10vh 20vw;
  font-family: "DomaineSansWebRegular";
  margin-top: var(--main-menu-height);
}

#static-pages-container h1 {
	font-size: 3.75em;
	line-height: 1.066em;
	-webkit-font-smoothing: antialiased;
  letter-spacing: -1px;
}

#static-pages-container h2 {
	font-size: 2.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#static-pages-container p {
	font-size: 1.125em;
	line-height: 1.66rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
  letter-spacing: -0.5px;
}

#static-pages-container a {
  color: inherit;
  font-size: inherit;
}

#static-pages-container a:hover {
  opacity: 0.5;
}

#static-pages-container h2 + p {
  margin-top: 45px;
}

#static-pages-container p + p {
  margin-top: 30px;
}

#static-pages-container ul {
  margin-left: 20px;
}

#static-pages-container ul li {
  margin-top: 30px;
}

#static-pages-container ul + p {
  margin-top: 30px;
}

#static-pages-container .content {
  width: 60vw;
  max-width: 700px;
  margin: 0 auto;
}


@media (max-width: 812px) {
  #static-pages-container {
    padding: 40px 20px;
  }

  #static-pages-container .content {
    width: 100%;
  }

  #static-pages-container h1 {
    font-size: 1.875em;
  }
  
  #static-pages-container h2 {
    font-size: 1.5em;
  }
  
  #static-pages-container p {
    font-size: 0.875rem;
    line-height: 1.35rem;
  }
  
  #static-pages-container h2 + p {
    margin-top: 20px;
  }
  
  #static-pages-container p + p {
    margin-top: 20px;
  }
  
  #static-pages-container ul li {
    margin-top: 20px;
  }
  
  #static-pages-container ul + p {
    margin-top: 20px;
  }
  
}


.PlayVideo {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 15px 0;
  font-family: 'basis-grotesque-medium';
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}
.PlayIcon {
  margin-right: 1.5em;
  position: relative;
}

.PlayIcon:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-left-width: 1em;
  border-top-width: .5em;
  border-bottom-width: .5em;
  border-right-width: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
}

#intro-page .PlayVideo {
  color: white;
}

@media (max-width: 1200px) {
  #intro-page .PlayVideo {
    color: black;
  }
}

.VideoModal {
  background-color: rgba(0,0,0,.8);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.VideoModal >div {
  width: 80vw;
  height: 0;
  padding-bottom: calc(80vw*.5625);
  position: relative;
}
.VideoModal>div>iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}
.VideoModal-wrap {
  width: 100%;
}
.VideoModal-close {
  color: white;
  position: fixed;
  left: calc(80vw + 50% - 80vw/2 + 10px);
  top: calc(50% - (80vw*.5625)/2);
  z-index: 1000;
}

.VideoModal-close:after,
.VideoModal-close:before {
  background-color: white;
  content: '';
  display: block;
  width: 2px;
  height: 18px;
  position: absolute;
  transform-origin: center;
}

.VideoModal-close:after {
  transform: rotate(45deg);
}
.VideoModal-close:before {
  transform: rotate(-45deg);
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:before, *:after {
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	-webkit-font-smoothing: subpixel-antialiased;
	color: #000;
	background: #fff;
}

@font-face {
	font-family: "DomaineSansWebRegular";
	src: local("DomaineSansWebRegular"), url(/static/media/DomaineSansTextWeb-Regular.084129a6.woff) format("woff");
}

@font-face {
	font-family: "basis-grotesque-mono";
	src: local("basis-grotesque-mono"), url(/static/media/basis-grotesque-mono.04fe76aa.woff) format("woff");
}

@font-face {
	font-family: "basis-grotesque-medium";
	src: local("basis-grotesque-medium"), url(/static/media/basis-grotesque-medium.b3106e2b.woff) format("woff");
}

/* Animated route transition group */

.fade-enter {
	top: 100%;
	z-index: 1;
}

.fade-enter-active {
	top: 0;
	transition: top 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	overflow: hidden;
}

.fade-exit-active {
	top: -100%;
	transition: top 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	overflow: hidden;
}

