/* scroll indicator */

#tour .scroll-indicator {
	position: sticky;

	bottom: 0; left: 0;

	height: var(--scroll-indicator-bar-height); width: 100%;
	line-height: var(--scroll-indicator-bar-height);

	font-family: 'basis-grotesque-medium';
	-webkit-font-smoothing: antialiased;
	background: transparent;
	color: white;
	font-size: 1.125em;

	cursor: pointer;
	user-select: none;
}

#tour.is-mobile .scroll-indicator {
	height: var(--scroll-indicator-bar-mobile-height); width: 100%;
	line-height: var(--scroll-indicator-bar-mobile-height);
	font-size: 0.875em;

	/* iPhone X */
	height: calc(var(--scroll-indicator-bar-mobile-height) + env(safe-area-inset-bottom));
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour .scroll-indicator .scroll-indicator-icon {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	opacity: 1.0;
	transition: opacity 500ms ease;
}

#tour .scroll-indicator.is-hidden .scroll-indicator-icon {
	opacity: 0;
}

#tour.is-desktop .scroll-indicator .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-height);
	width: var(--scroll-indicator-bar-width);
}

#tour.is-mobile .scroll-indicator .scroll-indicator-icon {
	height: var(--scroll-indicator-bar-mobile-height);
	width: var(--scroll-indicator-bar-mobile-height);
}

#tour .scroll-indicator .scroll-indicator-icon svg {
	display: block;
	stroke: white;
	transition: stroke 0.5s ease-in-out;
}

#tour.is-desktop .scroll-indicator-icon svg {
	width: 23px; height: 37px;
}

#tour.is-mobile .scroll-indicator-icon svg {
	width: 15.5px; height: 25px;
}

@keyframes bounce-scroll-indicator {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-15px);
	}
	60% {
		transform: translateY(-5px);
	}
}

#tour .scroll-indicator .scroll-indicator-icon.should-bounce {
	animation-name: bounce-scroll-indicator;
	animation-duration: 1.8s;
	animation-iteration-count: 3;
}

#tour .scroll-indicator .scroll-indicator-label {
	pointer-events: none;
	opacity: 1.0;
	transition: opacity 500ms ease;
}

#tour .scroll-indicator.is-hidden .scroll-indicator-label {
	opacity: 0;
}

#tour.is-desktop .scroll-indicator .scroll-indicator-label {
	margin-left: var(--scroll-indicator-bar-width);
}

#tour.is-mobile .scroll-indicator .scroll-indicator-label {
	margin-left: var(--scroll-indicator-bar-mobile-height);
}
