#tour #jump-to-menu {
	position: absolute;
	left: 0;
	width: 100%;
	background: white;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

@media (min-height: 1000px) {
	#tour #jump-to-menu.is-desktop {
		justify-content: center;
	}
}

#tour #jump-to-menu .menu-rows-wrapper {
	max-width: 1145px;
}

#tour #jump-to-menu.is-desktop {
	padding: 40px;
	top: var(--main-menu-height);
	height: calc(100vh - var(--main-menu-height));
}

#tour #jump-to-menu.is-mobile {
	padding: 40px 20px 20px 20px;
	top: var(--main-menu-mobile-height);
	height: calc(100vh - var(--main-menu-mobile-height));
}

@supports(padding: max(0px)) {
	/* iPhone X */
	#tour #jump-to-menu.is-mobile {
		padding-left: max(20px, env(safe-area-inset-left));
		padding-right: max(20px, env(safe-area-inset-right));
	}
}

#tour #jump-to-menu .menu-row {
	margin-bottom: 4vh;
	width: 100%;
}

#tour #jump-to-menu.is-desktop .menu-row {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
}

#tour #jump-to-menu .title-icon-wrapper {
	flex: 1 1 auto;
}

#tour #jump-to-menu.is-desktop .title-icon-wrapper {
	flex: 0 0 auto;
	width: 270px;
	margin-right: 30px;
	padding-top: 20px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-around;
}

#tour #jump-to-menu.is-mobile .title-icon-wrapper {
	margin-bottom: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

#tour #jump-to-menu .title-icon-wrapper .title {
	flex: 1 0 auto;
	font-size: 1.375em;
	-webkit-font-smoothing: antialiased;
}

#tour #jump-to-menu .title-icon-wrapper .icon {
	margin: 10px 25px 15px 0;

	width: 30px;
	height: 30px;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-end;
}

/* Section Menu */

#tour #jump-to-menu .menu-row .section-menu {
	width: 100%;
}

#tour #jump-to-menu.is-desktop .section-menu {
	flex: 1 1 auto;
}

#tour #jump-to-menu .section-menu .section-menu-row {
	display: flex;
	flex-flow: row wrap;
}

#tour #jump-to-menu.is-mobile .section-menu .section-menu-row {
	justify-content: space-between;
}

#tour #jump-to-menu a.jump-button {
	position: relative;
	display: block;

	font-family: 'basis-grotesque-medium';
	-webkit-font-smoothing: antialiased;
	font-size: 0.875em;
	color: inherit;

	margin-top: 2px; margin-bottom: 20px;

	background: transparent;
	border: 2px solid black;
	cursor: pointer;

	text-decoration: none;

	display: flex;
	align-items: center;
	justify-content: center;
}

#tour #jump-to-menu.is-desktop a.jump-button {
	padding: 25px 15px;
	margin: 20px 20px 0 0;
}

#tour #jump-to-menu.is-mobile a.jump-button {
	padding: 25px 5px; width: calc(50% - 10px);
}

#tour #jump-to-menu a svg {
	display: none;
	position: absolute;
	top: -2px; left: calc(50% - 11px);
	fill: inherit;
}

#tour #jump-to-menu a.is-here svg {
	display: block;
}

#tour #jump-to-menu a.jump-button:hover {
	background: black;
	color: white;
}

#tour #jump-to-menu a.jump-button:hover svg {
	fill: white;
}

#tour #jump-to-menu.is-mobile a.is-divider {
	min-width: 100%;
}

#tour #jump-to-menu a.jump-button.is-visited {
	opacity: 0.6;
}

#tour #jump-to-menu a.jump-button.is-visited.is-here {
	opacity: 1;
}

/* Taxes Overrides */

#tour #jump-to-menu .menu-row.taxes {
	color: var(--green-color);
	fill: var(--green-color);
}

#tour #jump-to-menu .menu-row.taxes .title-icon-wrapper {

}

#tour #jump-to-menu .menu-row.taxes a.jump-button {
	border: 2px solid var(--green-color);
	color: var(--green-color);
}

#tour #jump-to-menu .menu-row.taxes a.jump-button:hover {
	color: white;
	border: 2px solid var(--green-color);
	background: var(--green-color);
}

/* Spending Overrides*/

#tour #jump-to-menu .menu-row.spending {
	color: var(--blue-color);
	fill: var(--blue-color);
}

#tour #jump-to-menu .menu-row.spending .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.spending a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--blue-color);
}

#tour #jump-to-menu .menu-row.spending a.jump-button:hover {
	color: white;
	border: 2px solid var(--blue-color);
	background: var(--blue-color);
}

/* Economy Overrides */
#tour #jump-to-menu .menu-row.economy {
	color: var(--teal-color);
	fill: var(--teal-color);
}

#tour #jump-to-menu .menu-row.economy .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.economy a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--teal-color);
}

#tour #jump-to-menu .menu-row.economy a.jump-button:hover {
	color: white;
	border: 2px solid var(--teal-color);
	background: var(--teal-color);
}

/* Give Up Overrides */

#tour #jump-to-menu .menu-row.give-up {
	color: var(--red-color);
	fill: var(--red-color);
}

#tour #jump-to-menu .menu-row.give-up .title-icon-wrapper {
}

#tour #jump-to-menu .menu-row.give-up a.jump-button {
	border: 2px solid transparent;
	outline: 2px solid var(--red-color);
}

#tour #jump-to-menu .menu-row.give-up a.jump-button:hover {
	color: white;
	border: 2px solid var(--red-color);
	background: var(--red-color);
}

