#tour #keep-in-mind .content {
	padding: 0 var(--scroll-indicator-bar-width);
}

#tour #keep-in-mind h2 {
	max-width: 500px;
}

#tour #keep-in-mind p {
	max-width: 600px;
}