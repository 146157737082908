#tour .picker-menu.is-desktop {
	margin: 20px 0;
}

#tour .picker-menu.is-mobile {
	margin: 10px 0;
}

#tour .picker-menu.is-desktop {
	max-width: 800px;
}

#tour .picker-menu .picker-menu-btn {
	background: transparent;
	transition: background-color 120ms ease-out, border 120ms ease-out;
	border: 0;
	color: white;
	border: 2px solid white;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
	font-size: 0.875em;
	padding: 25px 15px;
	margin: 20px 20px 0 0;

	cursor: pointer;
	outline: none;
}

#tour .picker-menu.is-mobile .picker-menu-btn {
	width: 100%;
}

#tour .picker-menu .picker-menu-btn:hover {
	background: white;
	color: black; /* intended to be overwritten */
}

#tour .picker-menu .picker-menu-btn.is-visited {
	opacity: 0.5;
}

/* Skip to Spending */

#tour .picker-menu .picker-menu-btn.is-skip-btn.spending {
	background: var(--blue-color);
	border: 2px solid var(--blue-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.spending:hover {
	background-color: white;
	color: var(--blue-color)!important;
	border: 2px solid white;
}


/* Skip to Economy */

#tour .picker-menu .picker-menu-btn.is-skip-btn.economy {
	background: var(--teal-color);
	border: 2px solid var(--teal-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.economy:hover {
	background-color: white;
	color: var(--teal-color)!important;
	border: 2px solid white;
}

/* Skip to A Way Forward */

#tour .picker-menu .picker-menu-btn.is-skip-btn.a-way-forward {
	background: var(--red-color);
	border: 2px solid var(--red-color);
}

#tour .picker-menu .picker-menu-btn.is-skip-btn.a-way-forward:hover {
	background-color: white;
	color: var(--red-color)!important;
	border: 2px solid white;
}



