/* Animated Illustrations */

#tour.is-mobile .animated-illustration-wrapper {
	height: 100%;

	backface-visibility: hidden;
	transform-style: preserve-3d;
}

#tour img.animated-illustration {
	opacity: 0;
	transform: translate3d(2vw, 0, 0);
	transition: opacity 640ms ease, transform 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#tour.is-landscape img.animated-illustration {
	transform: translate3d(2vw, 0, 0);
}

#tour.is-landscape img.animated-illustration.is-flipped {
	transform: translate3d(-2vw, 0, 0);
}

#tour.is-portrait img.animated-illustration {
	transform: translate3d(0, 2vh, 0);
}

#tour.is-portrait img.animated-illustration.is-flipped {
	transform: translate3d(0, 2vh, 0);
}

#tour img.animated-illustration.is-in-view {
	opacity: 1;
	transform: translate3d(0, 0, 0)!important;
}