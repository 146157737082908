#tour-intro-page {
	background: white;
}

#tour-intro-page .gdp-graph {
	color: black;
}

/* overrides */

/* - gdp graph*/

#tour #tour-intro-page .gdp-graph.is-mobile .legend {
	background-image: linear-gradient(-180deg, #fff 0%, rgba(255, 255, 255, 0.00) 100%);
}
