#static-pages-container {
  padding: 10vh 20vw;
  font-family: "DomaineSansWebRegular";
  margin-top: var(--main-menu-height);
}

#static-pages-container h1 {
	font-size: 3.75em;
	line-height: 1.066em;
	-webkit-font-smoothing: antialiased;
  letter-spacing: -1px;
}

#static-pages-container h2 {
	font-size: 2.5em;
	line-height: 1.25em;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -1px;
}

#static-pages-container p {
	font-size: 1.125em;
	line-height: 1.66rem;
	font-weight: 0;
	-webkit-font-smoothing: antialiased;
  letter-spacing: -0.5px;
}

#static-pages-container a {
  color: inherit;
  font-size: inherit;
}

#static-pages-container a:hover {
  opacity: 0.5;
}

#static-pages-container h2 + p {
  margin-top: 45px;
}

#static-pages-container p + p {
  margin-top: 30px;
}

#static-pages-container ul {
  margin-left: 20px;
}

#static-pages-container ul li {
  margin-top: 30px;
}

#static-pages-container ul + p {
  margin-top: 30px;
}

#static-pages-container .content {
  width: 60vw;
  max-width: 700px;
  margin: 0 auto;
}


@media (max-width: 812px) {
  #static-pages-container {
    padding: 40px 20px;
  }

  #static-pages-container .content {
    width: 100%;
  }

  #static-pages-container h1 {
    font-size: 1.875em;
  }
  
  #static-pages-container h2 {
    font-size: 1.5em;
  }
  
  #static-pages-container p {
    font-size: 0.875rem;
    line-height: 1.35rem;
  }
  
  #static-pages-container h2 + p {
    margin-top: 20px;
  }
  
  #static-pages-container p + p {
    margin-top: 20px;
  }
  
  #static-pages-container ul li {
    margin-top: 20px;
  }
  
  #static-pages-container ul + p {
    margin-top: 20px;
  }
  
}