#tour #spending-history h1 {
	font-family: "basis-grotesque-mono";
}

#tour.is-desktop #spending-history h1 {
  font-size: 12.5em;
  margin-top: 45px;
}

#tour.is-mobile #spending-history h1 {
  font-size: 7.5em;
  margin-top: 20px;
}

#tour #spending-history h2 {
	max-width: 500px;
}

#tour #spending-history p {
	max-width: 600px;
}