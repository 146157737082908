#tour #taxes-landing {
	padding: 0 7%;
	background: var(--green-color);
}

#tour #taxes-landing-wrapper {
	background: var(--dark-green-color);
}

#tour #taxes-landing .content {
	z-index: 1;
}

#tour.is-mobile.is-portrait #taxes-landing .content {
	min-height: 60vh;
}

#tour #taxes-landing h2 {
	max-width: 500px;
}

#tour #taxes-landing p {
	max-width: 600px;
}

#tour #taxes-landing .background-graph {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
}

#tour #taxes-landing .background-graph svg {
	fill: var(--dark-green-color);
	display: block;
}

/*  overrides */

#tour #tour-taxes-container #taxes-landing-wrapper .scroll-indicator {
	background-image: none;
	border-top: 2px solid var(--green-color);
}