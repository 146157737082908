/* Overrides */

#tour #tour-spending-container .picker-menu.is-mobile .menu-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  /* iPhone X */
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#tour #tour-spending-container .picker-menu.is-desktop .menu-row {
  display: inline-block;
}

#tour #tour-spending-container .picker-menu.is-mobile a {
  flex: 0 1 calc(50% - 10px);
  display: block;
}

#tour #tour-spending-container .picker-menu.is-mobile .menu-row a:first-child{
  flex: 0 1 100%;
  width: 100%;
}

#tour #tour-spending-container .picker-menu.is-mobile .menu-row .picker-menu-btn {
  margin-right: 0;
}