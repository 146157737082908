#tour #tax-everyone-answer ul {
	margin: 10% 0;
}

#tour #tax-everyone-answer li {
	display: flex;
	flex-flow: row no-wrap;
	align-items: center;
}

#tour #tax-everyone-answer li + li {
	margin-top: 4%;
}

#tour #tax-everyone-answer .icon {
	flex: 0 0 auto;
	display: block;
	width: 40px;
	height: 30px;
	margin-right: 20px;
}
