#tour #calculator-landing-wrapper {
  background: var(--calculator-blue-color);
	color: #000;
	overflow: hidden;
}

#tour #calculator-landing p {
	max-width: 500px;
}

/* Split Layout */

#tour #calculator-landing .split-layout {
	display: flex;
}

#tour.is-desktop #calculator-landing .split-layout {
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	margin: auto;
	max-width: 1600px;
}

#tour.is-mobile #calculator-landing .split-layout {
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
  width: 100%;
}

#tour #calculator-landing .split-layout .split-layout-column:first-child {
  flex: 0 1 auto;
}

#tour.is-desktop #calculator-landing .split-layout .split-layout-column:first-child {
	padding-left: var(--scroll-indicator-bar-width);
	margin-right: 40px;
	max-width: 50vw;
}

#tour.is-desktop #calculator-landing .split-layout .split-layout-column:last-child {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
  justify-content: center;
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column:first-child {
	order: 2;
	padding: 0 20px 20px 20px;
	/* iPhone X */
	padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column:last-child {
	order: 1;

	flex: 0 1 auto;
	height: 50vh;
	position: relative;
	width: 100%;
}

#tour #calculator-landing .split-layout .split-layout-column img {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#tour.is-mobile #calculator-landing .split-layout .split-layout-column img {
	display: block;
	max-height: 100%;
	width: auto;
	max-width: 100%;
}

/* Overrides */

#tour #calculator-landing .picker-menu .picker-menu-btn {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-top: 45px;
	border-color: #000;
  color: #000;
}

#tour.is-mobile #calculator-landing .picker-menu .picker-menu-btn {
	margin-top: 20px;
}

#tour #calculator-landing .picker-menu a {
  text-decoration: none;
}

#tour #calculator-landing .picker-menu .picker-menu-btn:hover {
	background: black;
	color: var(--calculator-blue-color);
}

#tour #calculator-landing .picker-menu .picker-menu-btn .next-arrow-icon {
	stroke: #fff;
}

#tour #calculator-landing .picker-menu .picker-menu-btn:hover .next-arrow-icon {
	stroke: #000;
}

#tour #calculator-landing .picker-menu .picker-menu-btn .label {
	margin-right: 10px;
}
