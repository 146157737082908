#tour #spending-landing-wrapper {
	color: white;
	background: var(--dark-blue-color);
}

#tour #spending-landing {
	padding: 0 var(--scroll-indicator-bar-width);
	background: var(--blue-color);
}

#tour #spending-landing .content {
	z-index: 1;
}

#tour.is-mobile.is-portrait #spending-landing .content {
	min-height: 60vh;
}

#tour #spending-landing h2 {
	max-width: 700px;
}

#tour #spending-landing p {
	max-width: 600px;
}


#tour #spending-landing .background-graph {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
}

#tour #spending-landing .background-graph svg {
	fill: var(--dark-blue-color);
	display: block;
}

/*  overrides */

#tour #tour-spending-container #spending-landing-wrapper .scroll-indicator {
	background-image: none;
	border-top: 2px solid var(--blue-color);
}