#main-menu {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
  z-index: 10;
  
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

#main-menu #main-menu-bar {
	position: absolute;
	top: 0; left: 0;
  width: 100%;
  z-index: 11;

	color: #000;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	font-family: "basis-grotesque-medium";
	-webkit-font-smoothing: antialiased;
  font-size: 0.875em;
  
  transition: opacity 200ms ease-in-out;
	opacity: 1;
}

#main-menu #main-menu-bar a {
	text-decoration: none;
	color: inherit;
	transition: opacity 100ms ease-out, color 100ms ease-out;
}

#main-menu #main-menu-bar a:hover {
	opacity: 0.6;
}

#main-menu.is-desktop #main-menu-bar {
	padding: 0 40px 0 0;
	height: var(--main-menu-height); 
}

#main-menu.is-mobile #main-menu-bar {
	position: relative;
	height: var(--main-menu-mobile-height);
	/* iPhone X */
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

#main-menu.is-inverted #main-menu-bar {
  color: white;
}

#main-menu.is-hidden #main-menu-bar {
	opacity: 0;
}

