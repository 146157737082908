.PlayVideo {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 15px 0;
  font-family: 'basis-grotesque-medium';
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}
.PlayIcon {
  margin-right: 1.5em;
  position: relative;
}

.PlayIcon:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-left-width: 1em;
  border-top-width: .5em;
  border-bottom-width: .5em;
  border-right-width: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
}

#intro-page .PlayVideo {
  color: white;
}

@media (max-width: 1200px) {
  #intro-page .PlayVideo {
    color: black;
  }
}

.VideoModal {
  background-color: rgba(0,0,0,.8);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.VideoModal >div {
  width: 80vw;
  height: 0;
  padding-bottom: calc(80vw*.5625);
  position: relative;
}
.VideoModal>div>iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}
.VideoModal-wrap {
  width: 100%;
}
.VideoModal-close {
  color: white;
  position: fixed;
  left: calc(80vw + 50% - 80vw/2 + 10px);
  top: calc(50% - (80vw*.5625)/2);
  z-index: 1000;
}

.VideoModal-close:after,
.VideoModal-close:before {
  background-color: white;
  content: '';
  display: block;
  width: 2px;
  height: 18px;
  position: absolute;
  transform-origin: center;
}

.VideoModal-close:after {
  transform: rotate(45deg);
}
.VideoModal-close:before {
  transform: rotate(-45deg);
}
