#tour #economy-productivity-employment h2 {
	max-width: 500px;
}

#tour #economy-productivity-employment p {
	max-width: 600px;
}

#tour #economy-productivity-employment .row-wrapper {
  width: 100%;
  margin-top: 60px;
}

#tour.is-desktop #economy-productivity-employment .row-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#tour.is-desktop #economy-productivity-employment .row-wrapper .column-50:first-child {
  margin-right: 100px;
}

#tour.is-mobile #economy-productivity-employment .row-wrapper .column-50 {
  width: 100%;
}

#tour.is-mobile #economy-productivity-employment .row-wrapper .column-50:first-child {
  margin-bottom: 30px;
}

